import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import IsLoadingHOC from './../components/IsLoadingHOC';
import { pointHistoryGet } from '../redux/actions';
import { Container } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import '../assets/css/pagination.scss';

const PointHistory = props => {
  const { t } = useTranslation();
  const setLoading = props.setLoading;
  const [data, setData] = useState([]);
  const perPage = 10;
  const [pageNo, setPageNo] = useState(0);
  //const [totalPoint, setTotalPoint] = useState(0);
  const [totalRec, setTotalRec] = useState(0);
  const getData = async () => {
    props
      .pointHistoryGet(pageNo, perPage)
      .then(response => {
        if (response['data'] && typeof response['data'] === 'object') {
          const resData = response.data.content;
          let totp = 0;
          resData.forEach(val => {
            if (val.ledger === 'credit') {
              totp += val.points;
            } else {
              totp -= val.points;
            }
          });
          //setTotalPoint(totp);
          setTotalRec(response.data.metaData.totalRecs);
          const postData = resData.map((item, index) => {
            let itemDate = new Date(item.date);
            let newDate = `${itemDate.getMonth() + 1 > 9 ? '' : '0'}${itemDate.getMonth() + 1}/${itemDate.getDay() > 9 ? '' : '0'}${itemDate.getDay()}/${itemDate.getFullYear()} ${itemDate.getHours() > 9 ? '' : '0'}${itemDate.getHours()}:${itemDate.getMinutes() > 9 ? '' : '0'}${itemDate.getMinutes()}`;
            return { ...item, description: item.description, newDate: newDate, ledger: item.ledger === 'credit' ? '+' : '-', points: item.points };
          });
          setData(postData);
        }
        setLoading(false);
      })
      .catch(ex => {
        console.log('error in Point List Api', ex);
        setLoading(false);
      });
  };

  const scrollToDivHandler = id => {
    const violation = document.getElementById(id);
    if (violation) {
      window.scrollTo({
        top: violation.offsetTop,
        behavior: 'smooth',
      });
    }
  };
  
  useEffect(() => {
    setLoading(true);
    getData();
    scrollToDivHandler('root');
    //eslint-disable-next-line
  }, [pageNo]);
  
  return (
    <div>
      <div className="page-heading smoke-bg">
        <div className="overlay-mask">
          <h1 className="bold">
            <Trans>Points History</Trans>
          </h1>
        </div>
      </div>
      <Container fluid>
        <div className=" portal-history">
          <div className="timeline-wraper">
            <div className="timeline-content">
              <ul className="history-timeline">
                {data.length > 0 ? (
                  <>
                    {data.map((row, i) => {
                      return (
                        <li className="cell" key={i}>
                          <div className="points-show">
                            {row.ledger}
                            {row.points} pts
                          </div>
                          {row.name ? <h4>{row.name}</h4> : ''}
                          <div className="content" dangerouslySetInnerHTML={{ __html: row.description }} />
                          <div className="content">
                            <span className="material-icons">date_range</span> {row.newDate}
                          </div>
                        </li>
                      );
                    })}
                  </>
                ) : (
                  <li className="cell">
                    <h4>
                      <a href="/earn-points" title="Earn Now">
                        Earn Now
                      </a>
                    </h4>
                  </li>
                )}
              </ul>
            </div>

            <div className="row justify-content-center align-items-center my-2">
              <div className="col-auto">
                <span className="page-no-label">
                  {perPage * pageNo + 1} – {perPage * (pageNo + 1) > totalRec ? totalRec : perPage * (pageNo + 1)} of {totalRec}
                </span>
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-page-change"
                  title="Prev Page"
                  disabled={pageNo > 0 ? false : true}
                  onClick={e => {
                    if (pageNo > 0) setPageNo(pageNo - 1);
                  }}>
                  <span className="material-icons">chevron_left</span>
                </button>
                <button
                  className="btn btn-page-change"
                  title="Next Page"
                  disabled={perPage * (pageNo + 1) >= totalRec ? true : false}
                  onClick={e => { 
                    if (perPage * (pageNo + 1) < totalRec) setPageNo(pageNo + 1);
                  }}>
                  <span className="material-icons">navigate_next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default connect(null, { pointHistoryGet })(IsLoadingHOC(PointHistory, 'Loading....'));
