import React from "react";
import RewardsList from "../../components/rewards/RewardsList";
import CarouselCustom from "../../components/CarouselCustom";
class Rewards extends React.Component {
	render() {
		return (
			<>
				{/* <CarouselCustom /> */}
				<RewardsList />
			</>
		);
	}
}

export default Rewards;
