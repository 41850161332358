import React, { useEffect, useState } from 'react';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { connect, useSelector } from 'react-redux';
import { earnPointsPreview } from '../../redux/actions';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import WatchVideo from './WatchVideo';
import SurveyMonkeyQuiz from './SurveyMonkeyQuiz';
import ReferFriend from './ReferFriend';
import ReadEarn from './ReadEarn';
import { Trans } from 'react-i18next';
import ShareEarn from './ShareEarn';
import QuizQuestions from './QuizQuestions';
import StreakActivity from './StreakActivity';
const EarnPoints = ({ setLoading, earnPointsPreview }) => {
  const user = useSelector(({ user }) => user);
  const [data, setData] = useState({});
  const [apiCallStatus, setApiCallStatus] = useState(false);
  const [actionType, setActionType] = useState(null);
  let { id } = useParams();
  const pushDataLayer = (refId, elementTag, elementType = 'link') => {
    let url = `${window.location.pathname}${window.location.search ? `&${window.location.search}` : ''}`;
    if (window.gtmDataLayer) {
      window.gtmDataLayer.push({
        event: 'element_clicked',
        elementType: elementType,
        path: url,
        userId: user?.id || '',
        email: user?.email || '',
        username: user?.username || '',
        name: user?.name || '',
        refId: refId,
        refType: 'earn_points_preview',
        elementTag: elementTag,
        createdDate: new Date(),
      });
    }
  };
  useEffect(() => {
    setLoading(true);
    earnPointsPreview(id)
      .then(responseData => {
        if (responseData['data']) {
          let activityData = responseData.data;
          if (activityData.isTierEnabled && activityData.tierPoints) {
            activityData.points = activityData.tierPoints;
          }
          setData(activityData);
          setActionType(responseData['data']['actionType']);
          setLoading(false);
        } else {
          setData({});
          setLoading(false);
          setApiCallStatus(true);
        }
      })
      .catch(err => {
        setData({});
        setLoading(false);
        setApiCallStatus(true);
        console.log('Response Error:', JSON.stringify(err));
      });
    // eslint-disable-next-line
  }, [id]);
  if (apiCallStatus && Object.keys(data).length === 0) {
    return (
      <div className="earnpoint-page">
        <div className="survey-message-div">
          <Container>
            <h1 className="h1message">
              <Trans>Error</Trans> 404! <Trans>Activity not found</Trans>
            </h1>
          </Container>
        </div>
      </div>
    );
  } else if (actionType === 'view') {
    return (
      <div className="earnpoint-page">
        <div>
          <WatchVideo activityData={data} pushDataLayer={pushDataLayer} />
        </div>
      </div>
    );
  } else if (actionType === 'survey') {
    return (
      <div className="earnpoint-page">
        <div>{data.channel === 'quiz' ? <QuizQuestions pushDataLayer={pushDataLayer} activityData={data} /> : <SurveyMonkeyQuiz pushDataLayer={pushDataLayer} activityData={data} />}</div>
      </div>
    );
  } else if (actionType === 'refer') {
    return (
      <div className="earnpoint-page">
        <Container>
          <ReferFriend activityData={data} pushDataLayer={pushDataLayer} />
        </Container>
      </div>
    );
  } else if (actionType === 'read') {
    return (
      <div className="earnpoint-page">
        <Container>
          <ReadEarn activityData={data} pushDataLayer={pushDataLayer} />
        </Container>
      </div>
    );
  } else if (actionType === 'share') {
    return (
      <div className="earnpoint-page">
        <Container>
          <ShareEarn activityData={data} pushDataLayer={pushDataLayer} />
        </Container>
      </div>
    );
  } else if (actionType === 'login-n-earn') {
    return (
      <div className="earnpoint-page">
        <StreakActivity activityData={data} pushDataLayer={pushDataLayer} />
      </div>
    );
  } else if (actionType !== '' && Object.keys(data).length > 0) {
    return (
      <div className="earnpoint-page">
        <div className="survey-message-div">
          <Container>
            <h1 className="h1message">
              <Trans>Wrong Activity</Trans>
            </h1>
          </Container>
        </div>
      </div>
    );
  } else {
    return (
      <div className="earnpoint-page">
        <div className="survey-message-div">
          <Container>
            <h1 className="h1message">
              <Trans>Loading</Trans>......
            </h1>
          </Container>
        </div>
      </div>
    );
  }
};
export default connect(null, { earnPointsPreview })(IsLoadingHOC(EarnPoints, 'Loading....'));
