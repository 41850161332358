import React, { useState } from 'react';
import { logoutAction } from '../redux/actions';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import IsLoadingHOC from './IsLoadingHOC';
import { useSelector } from 'react-redux';

const Logout = props => {
  const user = useSelector(({ user }) => user);
  const handleLogout = () => {
    pushDataLayer('logout-mlink');
    props.setLoading(true);
    props
      .logoutAction()
      .then(response => {
        props.setLoading(false);
        window.location.href = '/';
      })
      .catch(ex => {
        console.log('Error In API Call', ex);
        props.setLoading(false);
        window.location.href = '/';
      });
  };
  const pushDataLayer = menu => {
    let url = `${window.location.pathname}${window.location.search ? `&${window.location.search}` : ''}`;
    if (window.gtmDataLayer) {
      window.gtmDataLayer.push({
        event: 'element_clicked',
        elementType: 'MLink',
        path: url,
        userId: user?.id || '',
        email: user?.email || '',
        username: user?.username || '',
        name: user?.name || '',
        refId: '',
        refType: '',
        elementTag: menu,
        createdDate: new Date(),
      });
    }
  };

  return (
    <Link id="button-logout" onClick={handleLogout} to={'#'}>
      <span className="mat-icon material-icons">exit_to_app</span> <Trans>Logout</Trans>
    </Link>
  );
};
export default connect(null, { logoutAction })(IsLoadingHOC(Logout, 'Loading....'));
