import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Modal, Container } from 'react-bootstrap';
// eslint-disable-next-line
import { signup, getLockupAction, preRegistrationAction } from '../../redux/actions';
import { connect } from 'react-redux';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GooglePlaces from '../../components/GooglePlaces';
//import Calendar from "react-calendar";
import Required from '../../components/Required';
import Captcha from '../../components/CaptchaClick';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import InputMask from 'react-input-mask';
var validator = require('validator');
const Register = ({ signup, setLoading }) => {
  const { t } = useTranslation();
  const confige = useSelector(state => state.app.config);
  const history = useHistory();
  const [place, setPlace] = useState({});
  const [showPopupNotverifiedUser, setShowPopupNotverifiedUser] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const referralid = query.get('refCode') ? query.get('refCode') : '';
  const source = query.get('src') ? query.get('src') : '';
  const promoCode = query.get('promoCode') ? query.get('promoCode') : '';
  let [captchaValue, setCaptchaValue] = useState(false);
  let [captchaEnable, setCaptchaEnable] = useState(false);
  let [captchaReset, setCaptchaReset] = useState(0);
  React.useEffect(() => {
    if ((confige?.captchaConfig?.recaptchaVersion || '').toLowerCase() === 'v3') {
      const interval = setInterval(() => {
        setCaptchaReset(captchaReset + 1);
      }, 45000);
      return () => clearInterval(interval);
    }
  }, [captchaReset, confige]);
  /*[START:INITIALIZE_DECLARATION]*/
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    confirmEmail: '',
    birth: '',
    homePhone: '',
    cellPhone: '',
    address: '',
    address2: '',
    country: '',
    state: '',
    suite: '',
    city: '',
    zip: '',
    gender: '',
    receiveEmail: '',
    receiveSms: '',
    companyName: '',
    shippingInstructions: '',
    password: '',
    passwordConfirm: '',
    socialSecurityNumber: '',
    latitude: 0,
    longitude: 0,
    additionalInfo: { visitorId: '', requestId: '' },
  });
  /*[END:INITIALIZE_DECLARATION]*/
  const [validation, setValidation] = useState({});
  const [error, setError] = useState({
    firstName: '',
    email: '',
    confirmEmail: '',
    homePhone: '',
    cellPhone: '',
    lastName: '',
    address: '',
    address2: '',
    country: '',
    state: '',
    suite: '',
    zip: '',
    city: '',
    shippingInstructions: '',
    receiveEmail: '',
    receiveSms: '',
    gender: '',
    passwordConfirm: '',
    password: '',
    socialSecurityNumber: '',
  });
  useEffect(() => {
    let items = {};
    let oldState = { ...data, password: '', passwordConfirm: '' };
    setData(oldState);
    if (confige.uiConfig) {
      confige.uiConfig.userFields.forEach(element => {
        if (element.name === 'email') {
          items['confirmEmail'] = element.validation;
        }
        items[element.name] = element.validation;
      });
      items.password = confige.uiConfig.passwordRule;
      items.passwordConfirm = confige.uiConfig.passwordRule;
      if (confige.fingerprinting) {
        if (confige.fingerprinting.enable) {
          const fpPromise = FingerprintJS.load({ apiKey: confige.fingerprinting.clientKey });
          // Get the visitor identifier when you need it.
          fpPromise
            .then(fp => fp.get())
            .then(result => {
              let fdata = JSON.parse(JSON.stringify(data));
              fdata.additionalInfo.visitorId = result.visitorId;
              fdata.additionalInfo.requestId = result.requestId;
              setData(fdata);
            });
        }
      }
    }
    if (confige.captchaConfig) {
      if (confige.captchaConfig.registrationCaptcha) {
        setCaptchaEnable(true);
      }
    }
    setValidation(items);
    // eslint-disable-next-line
  }, [confige, confige.uiConfig]);

  const handleChange = e => {
    const { name, value } = e.target;
    //console.log(name, validation);
    let errorMessage = '';
    let validationArray = validation[name];
    let check = false;
    let errmsg = '';
    for (let key in validationArray) {
      if (name === 'password' || name === 'passwordConfirm') {
        errmsg = `Your password must be between ${validationArray['minLength']} and ${validationArray['maxLength']} characters. It must contain at least one uppercase and one special character.`;
      }
      if (key === 'minLength') {
        if (value.length < validationArray[key] && value.length !== 0) {
          errorMessage = `${t('Should not be less then', { count: validationArray[key] })}.`;
          setError({ ...error, [name]: errmsg ? errmsg : errorMessage });
          check = true;
        }
      }
      if (key === 'maxLength') {
        if (value.length > validationArray[key]) {
          errorMessage = `${t('Should not be greater then', { count: validationArray[key] })}.`;
          setError({ ...error, [name]: errmsg ? errmsg : errorMessage });
          check = true;
        }
      }
      if (key === 'mandatory' && validationArray[key]) {
        if (!value.length) {
          errorMessage = `${t('Should not be empty')}.`;
          setError({ ...error, [name]: errmsg ? errmsg : errorMessage });
          check = true;
        }
      }
      if (key === 'hasNumeric' && validationArray[key] && value.search(/[0-9]/) < 0) {
        errorMessage = `${t('Should be contain number')}.`;
        setError({ ...error, [name]: errmsg ? errmsg : errorMessage });
        check = true;
      }
      if (key === 'hasAlpha' && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
        errorMessage = `${t('Should be contain alphabets')}.`;
        setError({ ...error, [name]: errmsg ? errmsg : errorMessage });
        check = true;
      }
      if (key === 'email' && validationArray[key]) {
        if (!validator.isEmail(value)) {
          errorMessage = `${t('Please enter a valid email address')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'upperCaseReq' && validationArray[key]) {
        if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have atleast one Upper Case latter')}.`;
          setError({ ...error, [name]: errmsg ? errmsg : errorMessage });
          check = true;
        }
      }
      if (key === 'lowerCaseReq' && validationArray[key]) {
        if (value.search(/[a-z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have atleast one Lower Case latter')}.`;
          setError({ ...error, [name]: errmsg ? errmsg : errorMessage });
          check = true;
        }
      }
      if (key === 'specialCharReq' && validationArray[key]) {
        // eslint-disable-next-line
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (!format.test(value) && value.length !== 0) {
          errorMessage = `${t('Should have atleast one special letter')}.`;
          setError({ ...error, [name]: errmsg ? errmsg : errorMessage });
          check = true;
        }
      }

      if (name === 'passwordConfirm') {
        if (data.password !== value) {
          setError({ ...error, passwordConfirm: `${t('Password and confirm password should be same')}.` });
        }
      }
      if (name === 'confirmEmail') {
        if (data.email !== value) {
          setError({ ...error, confirmEmail: `${t("Your emails don't match")}.` });
        }
      }
    }

    if (!check) {
      setError({ ...error, [name]: '' });
    }
    setData({ ...data, [name]: value });
  };
  const onSubmit = async e => {
    e.preventDefault();
    let errorArray = {};
    for (let name in data) {
      let value = data[name];
      let validationArray = validation[name];
      let errorMessage = '';
      if (name === 'birth' && isNaN(new Date(value)) && value.length !== 0) {
        errorMessage = `${t('Invalid')} ${t('Birth Date')}.`;
      } else if (name === 'birth') {
        errorMessage = '';
      }
      let errmsg = '';
      if (name === 'password' || name === 'passwordConfirm') {
        errmsg = `Your password must be between ${validationArray['minLength']} and ${validationArray['maxLength']} characters. It must contain at least one uppercase and one special character.`;
      }

      for (let key in validationArray) {
        if (key === 'email' && validationArray[key] && !validator.isEmail(value)) {
          errorMessage = `${t('Please enter a valid email address')}.`;
        } else if (key === 'minLength' && value.length < validationArray[key] && value.length !== 0) {
          errorMessage = `${t('Should not be less then', { count: validationArray[key] })}.`;
        } else if (key === 'maxLength' && value.length > validationArray[key]) {
          errorMessage = `${t('Should not be greater then', { count: validationArray[key] })}.`;
        } else if (key === 'mandatory' && validationArray[key] && !value.length) {
          errorMessage = `${t('Should not be empty')}.`;
        } else if (key === 'upperCaseReq' && value.search(/[A-Z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have atleast one Upper Case latter')}.`;
        } else if (key === 'lowerCaseReq' && value.search(/[a-z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have atleast one Lower Case latter')}.`;
        } else if (key === 'hasNumeric' && validationArray[key] && value.search(/[0-9]/) < 0) {
          errorMessage = `${t('Should be contain number')}.`;
        } else if (key === 'hasAlpha' && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
          errorMessage = `${t('Should be contain alphabets')}.`;
        } else if (key === 'specialCharReq' && value.length !== 0) {
          // eslint-disable-next-line
          var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
          if (!format.test(value)) {
            errorMessage = `${t('Should have atleast one special letter')}.`;
          }
        }
      }
      errorArray[name] = errmsg && errorMessage ? errmsg : errorMessage;

      if (name === 'password' || name === 'passwordConfirm') {
        if (data['password'] !== data['passwordConfirm']) {
          errorMessage = `${t('Password and confirm password should be same')}.`;
        }
        errorArray['passwordConfirm'] = errorMessage;
      }
      if (name === 'confirmEmail') {
        if (data['email'] !== data['confirmEmail']) {
          errorMessage = `${t("Your emails don't match")}.`;
        }
        errorArray['confirmEmail'] = errorMessage;
      }
    }
    setError(errorArray);
    let check = false;
    for (let key in errorArray) {
      if (errorArray[key]) {
        check = true;
      }
    }
    if (!captchaValue && captchaEnable) {
      toast.error(`${t('Error')}! ${t('Please select captcha')}.`);
      return false;
    }
    const newData = { ...data };
    if (captchaEnable) {
      newData['captchaVal'] = captchaValue;
    }
    delete newData.passwordConfirm;
    if (!check) {
      if (newData.birth) {
        let datevalue = new Date(newData.birth);
        const dd = String(datevalue.getDate()).padStart(2, '0');
        const mm = String(datevalue.getMonth() + 1).padStart(2, '0');
        const yyyy = datevalue.getFullYear();
        const formatedDate = yyyy + '-' + mm + '-' + dd;
        newData.birth = formatedDate;
      }
      if (!newData.hasOwnProperty('additionalInfo')) {
        newData['additionalInfo'] = {};
      }
      if (promoCode) {
        newData['additionalInfo']['promoCode'] = promoCode;
      }
      if (source) {
        newData['additionalInfo']['source'] = source;
      }
      setLoading(true);
      try {
        let signupCall = true;
        if (confige.usePreRegistration) {
          signupCall = false;
          let preRegistrationResp = await preRegistrationAction(newData);
          if (preRegistrationResp.data) {
            if (preRegistrationResp.data.valid) {
              signupCall = true;
              newData.additionalInfo.token = preRegistrationResp.data.token;
            } else {
              setLoading(false);
              toast.error(preRegistrationResp.data.errorReason);
              setShowPopupNotverifiedUser(true);
            }
          } else {
            setLoading(false);
            toast.error(`${t('Something went wrong')}.`);
          }
        }
        if (signupCall) {
          let signupResp = await signup(newData, referralid);
          if (captchaEnable) {
            setCaptchaReset(captchaReset + 1);
          }
          toast.success(signupResp);
          history.push('/login');
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (captchaEnable) {
          setCaptchaReset(captchaReset + 1);
        }
        if (typeof error === 'object') {
          error.forEach(item => {
            toast.error(item.message);
          });
        } else {
          toast.error(`${t('Something went wrong')}.`);
        }
      }
    }
  };
  useEffect(() => {
    if (place.hasOwnProperty('address')) {
      let err = { ...error };
      let curStateData = Object.assign({}, data);
      if (curStateData.hasOwnProperty('address')) {
        curStateData.address = place.address;
        err.address = '';
      }
      if (curStateData.hasOwnProperty('country')) {
        curStateData.country = place.country;
      }
      if (curStateData.hasOwnProperty('state')) {
        curStateData.state = place.state;
        err.state = '';
      }
      if (curStateData.hasOwnProperty('city')) {
        curStateData.city = place.city;
        err.city = '';
      }
      if (curStateData.hasOwnProperty('suite')) {
        curStateData.suite = place.street;
        err.suite = '';
      }
      if (curStateData.hasOwnProperty('zip')) {
        curStateData.zip = place.zip;
        err.zip = '';
      }
      if (curStateData.hasOwnProperty('latitude')) {
        curStateData.latitude = place.latitude;
      }
      if (curStateData.hasOwnProperty('longitude')) {
        curStateData.longitude = place.longitude;
      }
      setData(curStateData);
      setError(err);
    }
    // eslint-disable-next-line
  }, [place]);
  const googleSelectedAddressHandler = place => {
    setPlace(place);
  };
  const formatChars = {
    '1': '[12]',
    '0': '[0123456789]',
  };

  return (
    <div className="login-bg">
      <div className="register-page">
        <div className="container">
          <div className="row justify-content-center mt-4">
            <Card>
              <form role="form" method="post">
                <h1 className="upper-caps">{t('Register Now')}</h1>
                <p className="text-center mt-4">
                  Enter Leap Rewards with the same email address you used to create an account on LeapVapor.com. Please also enter a password. If you do not have a LeapVapor.com account,{' '}
                  <a href={`https://www.leapvapor.com/login.php?action=create_account&referrer=3tl&return_page=${window.location.href}`} target="_blank">
                    click here
                  </a>{' '}
                  to create one.
                </p>
                <div className="row mt-5">
                  {/*[START:EMAIL]*/}
                  <div className="form-group col-md-6 px-0">
                    <div className="labelfieldmove">
                      <input
                        type="email"
                        name="email"
                        onChange={handleChange}
                        className="form-control"
                        id="email"
                        placeholder=" "
                        // placeholder={`${t("Enter ")}${t("lang_key_email")}`}
                        aria-required="true"
                        aria-autocomplete="email"
                      />
                      <label htmlFor="email">
                        <Trans>lang_key_email</Trans>
                        {validation['email'] && validation['email'].mandatory ? <Required /> : ''}
                      </label>
                      <div className="input-error" role="alert">
                        {error.email}
                      </div>
                    </div>
                  </div>
                  {/*[END:EMAIL]*/}
                  {/*[START:CONFIRM EMAIL]*/}
                  <div className="form-group col-md-6 px-0">
                    <div className="labelfieldmove">
                      <input
                        type="email"
                        data-test="register-confirmEmail"
                        name="confirmEmail"
                        onChange={handleChange}
                        className="form-control"
                        id="confirmEmail"
                        placeholder=" "
                        // placeholder={`${t("Enter ")}${t("lang_key_confirm_email")}`}
                        aria-required="true"
                        aria-autocomplete="confirmEmail"
                      />
                      <label htmlFor="confirmEmail">
                        <Trans>lang_key_confirm_email</Trans>
                        {validation['confirmEmail'] && validation['confirmEmail'].mandatory ? <Required /> : ''}
                      </label>
                      <div className="input-error" role="alert">
                        {error.confirmEmail}
                      </div>
                    </div>
                  </div>
                  {/*[END:EMAIL]*/}
                </div>
                <div className="row">
                  {/*[START:PASSWORD]*/}
                  <div className="form-group col-md-6 px-0">
                    <div className="labelfieldmove">
                      <input
                        type="password"
                        name="password"
                        onChange={handleChange}
                        className="form-control"
                        id="password"
                        placeholder=" "
                        // placeholder={`${t("Enter ")}${t("password")}`}
                        aria-required="true"
                      />
                      <label htmlFor="password">
                        <Trans>password</Trans>
                        {validation['password'] && validation['password'].mandatory ? <Required /> : ''}
                      </label>
                      <div className="input-error" role="alert">
                        {error.password}
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6 px-0">
                    <div className="labelfieldmove">
                      <input
                        type="password"
                        name="passwordConfirm"
                        onChange={handleChange}
                        className="form-control"
                        id="password-confirm"
                        placeholder=" "
                        // placeholder={t("confirm password")}
                        aria-required="true"
                      />
                      <label htmlFor="password-confirm">
                        <Trans>confirm password</Trans>
                        {validation['passwordConfirm'] && validation['passwordConfirm'].mandatory ? <Required /> : ''}
                      </label>
                      <div className="input-error" role="alert">
                        {error.passwordConfirm}
                      </div>
                    </div>
                  </div>
                  {/*[END:PASSWORD]*/}
                  {/*[START:GOOGLECAPTCHA]*/}
                  {captchaEnable ? (
                    <div className="form-group col">
                      <label htmlFor="g-recaptcha-response" className="col-sm-12 invisible" aria-hidden="true">
                        Google captcha
                      </label>
                      <div className="col-sm-12">
                        <Captcha reset={captchaReset} parentCallback={setCaptchaValue} />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  {/*[END:GOOGLECAPTCHA]*/}
                </div>
                <div className="row flex-row-reverse align-items-center mb-5">
                  <div className="col-sm-6  text-center text-sm-right">
                    <button type="submit" id="register-submit-btn" onClick={onSubmit} className="btn btn-primary btn-submit">
                      Submit
                    </button>
                  </div>
                  <div className="col-sm-6">
                    {/* LOGIN */}
                    <div className="text-center text-sm-left mt-4 mt-sm-0">
                      <a href="/login" id="login-href">
                        Already a member? Login Now
                      </a>
                    </div>
                    {/* END LOGIN */}
                  </div>
                </div>
              </form>
            </Card>
          </div>
        </div>
      </div>
      <Modal className="show modal-bg-white" show={showPopupNotverifiedUser}>
        <Modal.Body>
          <Container className="mt-4">
            <Row>
              <Col xs={12}>
                <p className="text-center">
                  Sorry, this email is not associated with an Age Verified LeapVapor.com account. Please double-check your spelling or{' '}
                  <a href="https://www.leapvapor.com/login.php?action=create_account" target="_blank">
                    click here
                  </a>{' '}
                  to create a LeapVapor.com account. If you believe this is an error, please contact Leap Customer Support at <a href="mailto:support@leapvapor.com">support@LeapVapor.com</a>.
                </p>
              </Col>
              <Col xs={12} className="mt-4 mb-2 text-center">
                <button className="btn btn-primary" onClick={() => (window.location.href = '/')}>
                  Back
                </button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default connect(null, { signup })(IsLoadingHOC(Register, 'Wait .....'));
