import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const GoogleCaptchaV3 = props => {
  const [captchaRef, setCaptchaRef] = useState(null);
  const config = useSelector(state => state.app.config);
  const [captchaSiteKey, setCaptchaSiteKey] = useState(null);
  useEffect(() => {
    if (config.captchaConfig.recaptchaSiteKey) {
      setCaptchaSiteKey(config.captchaConfig.recaptchaSiteKey);
    }
  }, [config, config.captchaConfig]);
  useEffect(() => {
    if (captchaRef) {
      captchaRef.reset();
    }
  }, [props]);
  const handleVerify = token => {
    props.parentCallback(token);
  };
  return (
    <p align="center">
      {captchaSiteKey ? (
        <GoogleReCaptchaProvider reCaptchaKey={captchaSiteKey}>
          <GoogleReCaptcha ref={r => setCaptchaRef(r)} onVerify={handleVerify} />
        </GoogleReCaptchaProvider>
      ) : (
        ''
      )}
    </p>
  );
};
export default React.memo(GoogleCaptchaV3, (prevProps, curProps) => prevProps.reset === curProps.reset);
