import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import IsLoadingHOC from './../IsLoadingHOC';
import { getMyRewardsAction } from './../../redux/actions';
import { useSelector } from 'react-redux';
const MyRewards = props => {
  const { t } = useTranslation();
  const user = useSelector(state => state.user);
  const perPage = 10;
  const [pageNo, setPageNo] = useState(0);
  const [totalRec, setTotalRec] = useState(0);
  const setLoading = props.setLoading;
  const [rewardList, setRewardList] = useState([]);
  const [popupRewardData, setPopupRewardData] = useState({});
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  const getData = async () => {
    props.setLoading(true);
    getMyRewardsAction(pageNo, perPage)
      .then(responseData => {
        if (responseData.httpCode === 200) {
          setTotalRec(responseData.data?.metaData?.totalRecs);
          let resData = responseData.data.content;
          const postData = resData.map((item, index) => {
            let itemDate = new Date(item.date);
            const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            let newDate = `${monthNames[itemDate.getMonth()]} ${itemDate.getDay() > 9 ? '' : '0'}${itemDate.getDay()}, ${itemDate.getFullYear()}`;
            return { ...item, date: newDate };
          });
          setRewardList(postData);
        } else {
          setRewardList([]);
        }
        props.setLoading(false);
      })
      .catch(err => {
        props.setLoading(false);
        setRewardList([]);
        console.log('Response Error:', JSON.stringify(err));
      });
  };

  const scrollToDivHandler = id => {
    const violation = document.getElementById(id);
    if (violation) {
      window.scrollTo({
        top: violation.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    getData();
    scrollToDivHandler('root');
    //eslint-disable-next-line
  }, [pageNo]);
  const printDiv = divName => {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write('<html><head><title>Reward Redeem</title>');
    mywindow.document.write('</head><body >');
    mywindow.document.write('<h1>Reward Redeem</h1>');
    mywindow.document.write(document.getElementById(divName).innerHTML);
    mywindow.document.write('</body></html>');
    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    return true;
  };

  return (
    <div>
      <div className="page-heading smoke-bg">
        <div className="overlay-mask">
          <h1 className="bold">
            <Trans>My Rewards</Trans>
          </h1>
        </div>
      </div>
      <Container fluid>
        <div className=" portal-history">
          <div className="timeline-wraper">
            <div className="timeline-content">
              <ul className="history-timeline">
                {rewardList.length > 0 ? (
                  <>
                    {rewardList.map((row, i) => {
                      return (
                        <li className="cell" key={i}>
                          <div className="points-show">{row.points} pts</div>
                          <h4>
                            <button className="btn btn-link" onClick={() => setPopupRewardData(row)}>
                              {row.name ? row.name : 'Reward Redeem'}
                            </button>
                          </h4>
                          <div className="content" dangerouslySetInnerHTML={{ __html: row.description }} />
                          <div className="content">{row.date}</div>
                        </li>
                      );
                    })}
                  </>
                ) : (
                  <li className="cell">
                    <p align="center">Looks like you haven't redeemed any rewards yet.</p>
                    <h4>
                      <a href="/earn-points" title="Start Earning">
                        Start Earning
                      </a>
                    </h4>
                  </li>
                )}
              </ul>
            </div>
            <div className="row justify-content-center align-items-center my-2">
              <div className="col-auto">
                <span className="page-no-label">
                  {perPage * pageNo + 1} – {perPage * (pageNo + 1) > totalRec ? totalRec : perPage * (pageNo + 1)} of {totalRec}
                </span>
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-page-change"
                  title="Prev Page"
                  disabled={pageNo > 0 ? false : true}
                  onClick={e => {
                    if (pageNo > 0) setPageNo(pageNo - 1);
                  }}>
                  <span className="material-icons">chevron_left</span>
                </button>
                <button
                  className="btn btn-page-change"
                  title="Next Page"
                  disabled={perPage * (pageNo + 1) >= totalRec ? true : false}
                  onClick={e => {
                    if (perPage * (pageNo + 1) < totalRec) setPageNo(pageNo + 1);
                  }}>
                  <span className="material-icons">navigate_next</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Modal ClassName="modal-bg-white" show={Object.keys(popupRewardData).length > 0} size="xl" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Body>
          <Container>
            <Row className="text-center justify-content-center">
              <h1 className="text-center">
                <u>Reward Redemption Confirmation</u>
              </h1>
            </Row>
            <Row className="mb-4" id="Reward-redeem-popup-01">
              <Col sm={4}>
                <div className="pt-4">
                  <img src="/public/assets/images/Reward_Choice_$10.png" className="col" />
                </div>
              </Col>
              <Col sm={4}>
                <div className="pt-4">
                  <p>
                    Congratulations <span>{user.name}</span>!
                  </p>
                  <p>
                    <div className="content" dangerouslySetInnerHTML={{ __html: popupRewardData.description2 ? popupRewardData.description2 : popupRewardData.description }} />
                  </p>
                  <p>
                    For your records, your reference number is: <span>{popupRewardData.eventId?.toUpperCase()}</span>.
                  </p>
                </div>
              </Col>
              <Col sm={4}>
                <div className="pt-4">
                  <h2>
                    <u>Notes:</u>
                  </h2>
                  <ul>
                    <li>Print this page for reference (optional). A separate email with redemption instructions will be sent to you separately.</li>
                    <li>Follow the instructions on your email sent to {user.email} to redeem your reward. Please check your inbox to make sure our emails do not accidentally go to your spam or promotions folder.</li>
                  </ul>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center text-center mt-5 mb-3">
              <Col xs={6}>
                <button className="btn btn-primary" onClick={() => printDiv('Reward-redeem-popup-01')}>
                  PRINT
                </button>
              </Col>
              <Col xs={6}>
                <button className="btn btn-primary" onClick={() => setPopupRewardData({})}>
                  BACK
                </button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default IsLoadingHOC(MyRewards, 'Loading....');
