import React from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import TopRight from './TopRight';
import { useSelector, useDispatch } from 'react-redux';
//import { Link } from 'react-router-dom';
import { logo } from '../data/index';
import Logout from './Logout';
import { saveSelectedMenu } from '../redux/actions';
import NotificationCenter from './NotificationCenter';
import { Link, useHistory } from 'react-router-dom';
import { uiconfig } from '../redux/actions';
import { connect } from 'react-redux';

const Menu = ({ data, uiconfig }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState({ mainMenu: false, userMenu: false, notification: false });
  const { t } = useTranslation();
  const history = useHistory();
  const user = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const scrollToDivHandler = id => {
    dispatch(saveSelectedMenu(id));
    const violation = document.getElementById(id);
    if (violation) {
      window.scrollTo({
        top: violation.offsetTop,
        behavior: 'smooth',
      });
    }
  };
  const [unRead, setUnread] = React.useState(0);
  const mobileMenuClickHandler = type => {
    let m = { mainMenu: false, userMenu: false, notification: false };
    m[type] = isMenuOpen[type] ? false : true;
    setIsMenuOpen(m);
  };
  const navslide = () => {
    document.getElementById('mob-menu').classList.remove('open');
  };
  const navclose = () => {
    document.getElementById('mobuser-menu').classList.remove('show');
  };
  const pushDataLayer = menu => {
    let url = `${window.location.pathname}${window.location.search ? `&${window.location.search}` : ''}`;
    if (window.gtmDataLayer) {
      window.gtmDataLayer.push({
        event: 'element_clicked',
        elementType: 'MLink',
        path: url,
        userId: user?.id || '',
        email: user?.email || '',
        username: user?.username || '',
        name: user?.name || '',
        refId: '',
        refType: '',
        elementTag: menu,
        createdDate: new Date(),
      });
    }
  };
  React.useEffect(() => {
    (async () => {
      uiconfig()
        .then(response => {
          if (!response.data || response.httpCode !== 200) {
            //history.push("/maintenance");
          }
        })
        .catch(error => {
          console.log(error);
          history.push('/maintenance');
        });
    })();
  }, [uiconfig]);
  return (
    <Fragment>
      <div className="d-none">
        <button id="button-navbar-toggler" className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span id="span-navbar-toggler-icon" className="navbar-toggler-icon"></span>
        </button>
        {/* <button type="button" id="navbarSupportedContent" className="navbar-toggler collapsed" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span></span>
					<span></span>
					<span></span>
				</button> */}

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link
                className="nav-link"
                id="href-home"
                to="/"
                onClick={e => {
                  scrollToDivHandler('root');
                  pushDataLayer('home-mlink');
                }}>
                {t('Home')}
              </Link>
            </li>
            {/* <li className="nav-item">
						<Link
							className="nav-link"
							id="href-participate"
							to="/"
							onClick={(e) => {
								scrollToDivHandler("participate-content");
							}}>
							{t("How To Participate")}
						</Link>
						</li> */}
            {!user.accessToken ? (
              <li className="nav-item">
                {/*[START:LOGIN]*/}
                <a id="href-login" className="nav-link" href="/login" onClick={() => pushDataLayer('login-mlink')}>
                  {t('login')}{' '}
                </a>
                {/*[END:LOGIN]*/}
              </li>
            ) : (
              <>
                <li className="nav-item">
                  <Link className="nav-link" id="href-submit-code" to="/earn-points/pincode" onClick={() => pushDataLayer('submit_code-mlink')}>
                    {t('Submit Code')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" id="href-rewards" to="/rewards" onClick={() => pushDataLayer('rewards-mlink')}>
                    {t('Rewards')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" id="href-earn-points" to="/earn-points" onClick={() => pushDataLayer('earn_points-mlink')}>
                    {t('Earn Points')}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" id="href-contest" to="/contest" onClick={() => pushDataLayer('contest-mlink')}>
                    {t('Contest')}
                  </Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" id="href-find-store" href="https://www.leapvapor.com/store-locator/" target="_blank" onClick={() => pushDataLayer('find_a_store-mlink')}>
                    FIND A STORE
                  </a>
                </li>
              </>
            )}
            {user.accessToken ? (
              <></>
            ) : (
              <li className="nav-item">
                <a className="nav-link" id="href-registration" href="/signup" onClick={() => pushDataLayer('register-mlink')}>
                  {t('Register')}
                </a>
              </li>
            )}
          </ul>
          {/* My All-Access , Points History, My Rewards, Profile, Connect Movie ANYHERE, Logout */}

          {user.accessToken ? (
            <ul className="navbar-nav me-0 d-none d-sm-flex">
              <TopRight />
            </ul>
          ) : (
            <span></span>
          )}
        </div>
        <ul className="navbar-nav me-0 navbar-mob d-flex d-sm-none">
          <TopRight />
        </ul>
      </div>

      <div className="header-inner">
        <div className="logo">
          <Link id="href-logo" to="/">
            <img alt="Leap Vapor Logo" src={logo}></img>
          </Link>
        </div>
        <div className="menu-desktop">
          <ul>
            <li>
              <Link
                id="href-home"
                to="/"
                onClick={e => {
                  scrollToDivHandler('root');
                  pushDataLayer('home-mlink');
                }}>
                {t('Home')}
              </Link>
            </li>
            {!user.accessToken ? (
              <>
                <li>
                  <a id="href-login" href="/login" onClick={() => pushDataLayer('login-mlink')}>
                    {t('login')}{' '}
                  </a>
                </li>
                <li>
                  <a id="href-registration" href="/signup" onClick={() => pushDataLayer('register-mlink')}>
                    {t('Register')}
                  </a>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link id="href-submit-code" to="/earn-points/pincode" onClick={() => pushDataLayer('submit_code-mlink')}>
                    {t('Submit Code')}
                  </Link>
                </li>
                <li>
                  <Link id="href-rewards" to="/rewards" onClick={() => pushDataLayer('rewards-mlink')}>
                    {t('Rewards')}
                  </Link>
                </li>
                <li>
                  <Link id="href-earn-points" to="/earn-points" onClick={() => pushDataLayer('earn_points-mlink')}>
                    {t('Earn Points')}
                  </Link>
                </li>
                <li>
                  <Link id="href-contest" to="/contest" onClick={() => pushDataLayer('contest-mlink')}>
                    {t('Contest')}
                  </Link>
                </li>
                <li>
                  <a id="href-find-store" href="https://www.leapvapor.com/store-locator/" target="_blank" onClick={() => pushDataLayer('find_a_store-mlink')}>
                    FIND A STORE
                  </a>
                </li>
              </>
            )}
          </ul>
          {user.accessToken ? (
            <>
              <div className="user-profile">
                <img className="menu-reward-icon1" src="/public/assets/images/Leap-reward-icon.png" />
                <div className="pts">
                  <p>{user.pointBalance} pts</p>
                  <p>{user.name ? user.name : user.username}</p>
                </div>
                <div className="account-desktop-menu">
                  <ul>
                    <li>
                      <Link id="href-profile" to="/update-profile" onClick={() => pushDataLayer('update_profile-mlink')}>
                        <span className="mat-icon material-icons">contact_page</span> {t('Update Profile')}
                      </Link>
                    </li>
                    <li>
                      <Link id="href-point-history" to="/points-history" onClick={() => pushDataLayer('point_history-mlink')}>
                        <span className="mat-icon material-icons">timeline</span> {t('Point History')}
                      </Link>
                    </li>
                    <li>
                      <Link id="href-my-rewards" to="/my-rewards" onClick={() => pushDataLayer('my_rewards-mlink')}>
                        <span className="mat-icon material-icons">card_giftcard</span> {t('My Rewards')}
                      </Link>
                    </li>
                    <li>
                      <Link id="program-perks" to="/program-perks" onClick={() => pushDataLayer('program_perks-mlink')}>
                        <span className="mat-icon material-icons">emoji_events</span> Program Perks
                      </Link>
                    </li>
                    <li>
                      <Link id="exciting-changes" to="/exciting-changes" onClick={() => pushDataLayer('exciting_changes-mlink')}>
                        <span className="mat-icon material-icons">track_changes</span> Exciting Changes
                      </Link>
                    </li>
                    <li>
                      <Link id="href-support" to="/support" onClick={() => pushDataLayer('support-mlink')}>
                        <span className="mat-icon material-icons">support</span> {t('Support')}
                      </Link>
                    </li>
                    <li>
                      <Logout />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="notification">
                <NotificationCenter isMobile={false} />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="menu-mobile-icons">
          <span
            onClick={() => {
              mobileMenuClickHandler('mainMenu');
            }}
            className="mat-icon material-icons">
            menu
          </span>
          {!user.accessToken ? (
            <></>
          ) : (
            <>
              <span
                onClick={() => {
                  mobileMenuClickHandler('userMenu');
                }}
                className="mat-icon material-icons">
                supervised_user_circle{' '}
              </span>
              <span
                onClick={() => {
                  mobileMenuClickHandler('notification');
                }}
                className="mat-icon mat-badge mat-badge-warn material-icons mat-badge-overlap mat-badge-above mat-badge-after mat-badge-medium">
                {' '}
                add_alert
                <span id="mat-badge-content-1" className="mat-badge-content mat-badge-active">
                  {unRead}
                </span>
              </span>
            </>
          )}
        </div>
      </div>

      <div className={`mobile-drawer ${isMenuOpen.mainMenu ? 'open' : ''}`} id="mob-menu">
        <div
          className="overlay"
          onClick={() => {
            mobileMenuClickHandler('mainMenu');
          }}></div>
        <div className="drawer-inner">
          <div className="title">
            <h3>Navigation</h3>
            <span
              onClick={() => {
                mobileMenuClickHandler('mainMenu');
              }}
              className="mat-icon material-icons">
              close{' '}
            </span>
          </div>
          <ul className="mobile-nav">
            <li>
              <span className="mat-icon material-icons">contact_support</span>
              <Link
                id="href-home"
                to="/"
                onClick={e => {
                  navslide(e);
                  pushDataLayer('home-mlink');
                }}>
                {t('Home')}
              </Link>
            </li>
            {!user.accessToken ? (
              <>
                <li>
                  <span className="mat-icon material-icons">login</span>
                  <a
                    id="href-login"
                    href="/login"
                    onClick={e => {
                      navslide(e);
                      pushDataLayer('login-mlink');
                    }}>
                    {t('login')}{' '}
                  </a>
                </li>
                <li>
                  <span className="mat-icon material-icons">how_to_reg</span>
                  <a
                    id="href-registration"
                    href="/signup"
                    onClick={e => {
                      navslide(e);
                      pushDataLayer('register-mlink');
                    }}>
                    {t('Register')}
                  </a>
                </li>
              </>
            ) : (
              <>
                <li>
                  <span className="mat-icon material-icons">dynamic_form</span>
                  <Link
                    id="href-submit-code"
                    to="/earn-points/pincode"
                    onClick={e => {
                      navslide(e);
                      pushDataLayer('submit_code-mlink');
                    }}>
                    {t('Submit Code')}
                  </Link>
                </li>
                <li>
                  <span className="mat-icon material-icons">emoji_events</span>
                  <Link
                    id="href-rewards"
                    to="/rewards"
                    onClick={e => {
                      navslide(e);
                      pushDataLayer('rewards-mlink');
                    }}>
                    {t('Rewards')}
                  </Link>
                </li>
                <li>
                  <span className="mat-icon material-icons">storefront</span>
                  <Link
                    id="href-earn-points"
                    to="/earn-points"
                    onClick={e => {
                      navslide(e);
                      pushDataLayer('earn_points-mlink');
                    }}>
                    {t('Earn Points')}
                  </Link>
                </li>
                <li>
                  <span className="mat-icon material-icons">casino</span>
                  <Link
                    id="href-contest"
                    to="/contest"
                    onClick={e => {
                      navslide(e);
                      pushDataLayer('contest-mlink');
                    }}>
                    {t('Contest')}
                  </Link>
                </li>
                <li>
                  <span className="mat-icon material-icons">store</span>
                  <a
                    id="href-find-store"
                    href="https://www.leapvapor.com/store-locator/"
                    target="_blank"
                    onClick={e => {
                      navslide(e);
                      pushDataLayer('find_a_store-mlink');
                    }}>
                    FIND A STORE
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
      <div id="main-header">
        <div className={`notification-center-mob  ${isMenuOpen.notification ? 'show' : ''}`}>
          <NotificationCenter isMobile={true} mobileMenuClickHandler={mobileMenuClickHandler} setUnread={setUnread} />
        </div>
        <div className={`account-menu ${isMenuOpen.userMenu ? 'show' : ''}`} id="mobuser-menu">
          <div className="notify-header">
            <p>Account Menu</p>
            <span
              onClick={() => {
                mobileMenuClickHandler('userMenu');
              }}
              className="mat-icon material-icons">
              close{' '}
            </span>
          </div>
          <div className="user-profile">
            <div className="account-overview">
              <img alt="Tier Icon" src="/public/assets/images/Leap-reward-icon.png" />
              <div className="pts">
                <p>{user.pointBalance} pts</p>
                <p>{user.name ? user.name : user.username}</p>
              </div>
            </div>
            <div className="account-desktop-menu">
              <ul>
                <li>
                  <Link
                    id="href-profile"
                    to="/update-profile"
                    onClick={e => {
                      navclose(e);
                      pushDataLayer('update_profile-mlink');
                    }}>
                    <span className="mat-icon material-icons">contact_page</span> {t('Update Profile')}
                  </Link>
                </li>
                <li>
                  <Link
                    id="href-point-history"
                    to="/points-history"
                    onClick={e => {
                      navclose(e);
                      pushDataLayer('point_history-mlink');
                    }}>
                    <span className="mat-icon material-icons">timeline</span> {t('Point History')}
                  </Link>
                </li>
                <li>
                  <Link
                    id="href-my-rewards"
                    to="/my-rewards"
                    onClick={e => {
                      navclose(e);
                      pushDataLayer('my_rewards-mlink');
                    }}>
                    <span className="mat-icon material-icons">card_giftcard</span> {t('My Rewards')}
                  </Link>
                </li>
                <li>
                  <Link
                    id="program-perks"
                    to="/program-perks"
                    onClick={e => {
                      navclose(e);
                      pushDataLayer('program_perks-mlink');
                    }}>
                    <span className="mat-icon material-icons">emoji_events</span> Program Perks
                  </Link>
                </li>
                <li>
                  <Link
                    id="exciting-changes"
                    to="/exciting-changes"
                    onClick={e => {
                      navclose(e);
                      pushDataLayer('exciting_changes-mlink');
                    }}>
                    <span className="mat-icon material-icons">track_changes</span> Exciting Changes
                  </Link>
                </li>
                <li>
                  <Link
                    id="href-support"
                    to="/support"
                    onClick={e => {
                      navclose(e);
                      pushDataLayer('support-mlink');
                    }}>
                    <span className="mat-icon material-icons">support</span> {t('Support')}
                  </Link>
                </li>
                <li>
                  <Logout />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(null, { uiconfig })(Menu);
