import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { connect } from 'react-redux';
import { pointsUpdate } from '../../redux/actions';
import { TwitterIcon, TwitterShareButton, FacebookIcon, FacebookShareButton } from 'react-share';
import EarnPointList from './EarnPointList';
const ShareEarn = props => {
  const { t } = useTranslation();
  const activityData = props.activityData;
  const setLoading = props.setLoading;
  let { id } = useParams();
  const shareButton = React.useRef(null);
  const [activityStatus, setActivityStatus] = useState({ status: false, activityEventId: '', refKey: '' });
  const onShareButtonClickHandler = () => {
    setLoading(true);
    let data = {
      reference: id,
      state: 'started',
    };
    props
      .pointsUpdate(data, 'post')
      .then(responseData => {
        if (responseData['data']) {
          setActivityStatus(Object.assign({}, activityStatus, { status: true, activityEventId: responseData.data.id, refKey: responseData.data.extraInfo?.refKey }));
        } else {
          setActivityStatus(Object.assign({}, activityStatus, { status: false, activityEventId: 'none', refKey: '' }));
        }
        setLoading(false);
        shareButton.current.click();
      })
      .catch(err => {
        setActivityStatus(Object.assign({}, activityStatus, { status: false, activityEventId: 'none', refKey: '' }));
        setLoading(false);
        shareButton.current.click();
      });
  };
  return (
    <div className="portal-point-list-container">
      <Container fluid>
        <Row className="label">
          <Col md={10}>
            <Link id="headerParentLink" className="parent-link" to="/earn-points">
              <Trans>Earn Points</Trans>
            </Link>{' '}
            <b>{activityData['title'] ? activityData.title : ''}</b>
          </Col>
          <Col className="float-right" md={2}></Col>
        </Row>
        <Container fluid>
          <Row xs={1} md={1} lg={1}>
            <b className="uppercase">
              <a className="">
                <Trans>Complete this</Trans>
              </a>{' '}
              {activityData['title'] ? activityData.title : ''} <Trans>and get</Trans> {activityData['points'] ? activityData.points : 0} <Trans>Points</Trans>
            </b>
          </Row>
          <Row xs={1} md={1} lg={1}>
            <p className="description">{activityData['description'] ? activityData.description : ''}</p>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        <Row xs={1} md={2} lg={2}>
          <Col>
            <Card>
              <Card.Img variant="top" src={activityData.cardHeaderImage} />
              <Card.Body>
                <Card.Text>
                  <Trans>Perform this action to get</Trans> {activityData['points'] ? activityData.points : 0} <Trans>Points</Trans>{' '}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <h4 className="title">
              <b>{activityData['title'] ? activityData.title : ''}</b>
            </h4>
            <p className="description">{activityData['description'] ? activityData.description : ''}</p>
            <p align="center">
              <Button variant="outline-info">
                {activityData['channel'] === 'twitter' ? (
                  <>
                    <span onClick={onShareButtonClickHandler}>
                      <TwitterIcon size={32} round={true} /> <Trans>Share</Trans>
                    </span>
                    <TwitterShareButton ref={shareButton} url={activityStatus.refKey} title={[`${activityData.data}`]} className="Demo__some-network__share-button" />
                  </>
                ) : (
                  <>
                    <span onClick={onShareButtonClickHandler}>
                      <FacebookIcon size={32} round={true} /> <Trans>Share</Trans>
                    </span>
                    <FacebookShareButton ref={shareButton} url={window.location.origin} hashtag={`#${activityStatus.refKey}`} quote={[activityData.data]} className="Demo__some-network__share-button" />
                  </>
                )}
              </Button>
            </p>
          </Col>
        </Row>
        <EarnPointList id={activityData['id']} type="activity" title={activityData['title']} />
      </Container>
    </div>
  );
};
export default React.memo(connect(null, { pointsUpdate })(IsLoadingHOC(ShareEarn, 'Loading....')));
