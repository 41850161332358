import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCampaignSubsetData } from '../redux/actions';
import IsLoadingHOC from './IsLoadingHOC';
import { ProgressBar, Collapse } from 'react-bootstrap';

const TierInformation = props => {
  const token = useSelector(({ user }) => user.accessToken);
  const user = useSelector(({ user }) => user);
  const [useTier, setUserTier] = useState(null);
  const [open, setOpen] = useState(true);
  const [userTierIndex, setUserTierIndex] = useState(0);
  const [data, setData] = useState(null);
  const [tierStatusMsg, setTierStatusMsg] = useState('');
  const [progressBarPercent, setProgressBarPercent] = useState(0);
  const parseJwt = token => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };
  useEffect(() => {
    if (token) {
      let tokenData = parseJwt(token);
      if (tokenData) {
        setUserTier(tokenData.tier);
      } else {
        setUserTier(null);
      }
      props.setLoading(true);
      getCampaignSubsetData({ reqAttributes: { pointTierConfig: 1 } })
        .then(response => {
          if (response.data.content) {
            setData(response.data.content[0].pointTierConfig);
          }
          props.setLoading(false);
        })
        .catch(ex => {
          console.log('Error In API Call', ex);
          setData(null);
          props.setLoading(false);
        });
    }
    // eslint-disable-next-line
  }, [token]);
  useEffect(() => {
    if (useTier && token && data) {
      let ind = data.findIndex(v => parseInt(v.tierId) === parseInt(useTier));
      setUserTierIndex(ind);
      let lastLevalUpPoints = data[data.length - 1]['level-up-points'];
      if (ind === data.length - 1 || user.pointBalance >= lastLevalUpPoints) {
        setProgressBarPercent(100);
        setTierStatusMsg(`You made it to the top ${user.name ? user.name : user.username}, congratulations from the Leap Team!`);
      } else if (ind < data.length - 1 && ind >= 0) {
        let nextLevalUpPoints = data[ind + 1]['level-up-points'];
        if (Math.round(nextLevalUpPoints - user.pointBalance) <= 100) {
          setTierStatusMsg(`You’re almost there ${user.name ? user.name : user.username}, just ${Math.round(nextLevalUpPoints - user.pointBalance)} points away to reach ${data[ind + 1].tierName}.`);
        } else {
          setTierStatusMsg(`You’re making progress  ${user.name ? user.name : user.username}, just ${Math.round(nextLevalUpPoints - user.pointBalance)} points away to reach ${data[ind + 1].tierName}.`);
        }
        //setProgressBarPercent(Math.round((10 / nextLevalUpPoints) * 100));
        setProgressBarPercent(Math.round((user.pointBalance / nextLevalUpPoints) * 100));
      }
    }
  }, [token, useTier, data]);
  if (token) {
    return (
      <Container fluid className="tier-information">
        <Row md={1}>
          <Col className="text-right">
            <button className="toggle-switch" onClick={() => setOpen(!open)} dangerouslySetInnerHTML={{ __html: open ? '<span class="material-icons">expand_more</span>' : '<span class="material-icons">expand_less</span>' }} />
          </Col>
        </Row>
        <Collapse in={open}>
          <div id="example-collapse-text">
            <Row className="justify-content-md-center">
              <Col className="tier-col">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="current-level d-flex">
                    <span className="float-left d-none d-sm-block">
                      <img height="70" src={`/public/assets/images/levels/tier_icon_${useTier && data ? data[userTierIndex].tierId : '1'}.png`} alt={useTier && data ? data[userTierIndex].tierName : ''} />
                    </span>
                    <span className="level-name">
                      <p>
                        <Trans>Current level</Trans>
                      </p>
                      <h5>
                        <Trans>{useTier && data ? data[userTierIndex].tierName : ''}</Trans>
                      </h5>
                    </span>
                  </div>
                  <div className="current-percentage">
                    <ProgressBar variant="success" now={progressBarPercent} label={`${progressBarPercent}%`} visuallyHidden>
                      {progressBarPercent === 0 ? <p className="mt-3 px-3" style={{ width: '100%' }}>{`${progressBarPercent}%`}</p> : ''}
                    </ProgressBar>
                    <p className="pt-2">{tierStatusMsg}</p>
                  </div>
                  {data && data.length - 1 === userTierIndex ? (
                    ''
                  ) : (
                    <div className="next-level d-flex">
                      <span className="level-name float-left text-right">
                        <p>
                          <Trans>Next level</Trans>
                        </p>
                        <h5>
                          <Trans>{useTier && data ? data[userTierIndex + 1].tierName : ''}</Trans>
                        </h5>
                      </span>
                      <span className="float-right d-none d-sm-block">
                        <img height="70" src={`/public/assets/images/levels/tier_icon_${useTier && data ? data[userTierIndex + 1].tierId : '1'}.png`} alt={useTier && data ? data[userTierIndex + 1].tierName : ''} />
                      </span>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Container>
    );
  } else return '';
};
export default IsLoadingHOC(TierInformation, 'Loading....');
