import React from 'react';
import Contests from '../../components/Contests';
const Contest = props => {
  return (
    <>
      <div className="">
        <div className="page-heading profile-img">
          <img src="/public/assets/images/contest.jpg" alt="Redeem Your Leap® Smart Rewards" />
          <div className="overlay-mask">
            <h1>Leap Smart Contest</h1>
            <p>Take a chance and participate in various contest to earn points or get exclusive prizes!</p>
          </div>
        </div>
      </div>
      <Contests pagesize={8} />
    </>
  );
};

export default Contest;
