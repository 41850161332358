import React, { useState, useEffect } from "react";
import Menu from "../MicrositeMenu";
//import Menu from "../Menu";
import { getManu } from "../../redux/actions";
import { connect } from "react-redux";
//import TopRight from "../TopRight";
import { Row, Col, Modal, Container } from "react-bootstrap";
import TopLeft from "../TopLeft";
import { Fragment } from "react";

const Header = ({ getManu }) => {
	const [menu, setMenu] = useState([]);
	const [ageVerificationPopupShow, setAgeVerificationPopupShow] = useState(false);
	useEffect(()=>{
		let allowOpen=window.sessionStorage.getItem("allowSiteOpen");
		if(!allowOpen){
			setAgeVerificationPopupShow(true);
		}
	},[window.sessionStorage.getItem("allowSiteOpen")])
	useEffect(() => {
		getManu().then((response) => {
			setMenu(response.body.menuStructure);
		});
	}, [getManu]);
	const handlePopupBtn=(type)=>{
		if(type==="yes"){
			setAgeVerificationPopupShow(false);
			window.sessionStorage.setItem("allowSiteOpen",true)
		}else{
			window.location.href="https://www.google.com/";
		}
	}
	return (
		<Fragment>
			<div className="sticky-top">
				<div className="warning-msg__wraper">
					<div className="warning-msg11">
						{/* <div className="msg-heading">
							<span>
								<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M49.5079 41.8125C42.1735 28.4141 34.836 15.0156 27.5001 1.6172C27.2554 1.15351 26.8889 0.765376 26.4399 0.494634C25.991 0.223892 25.4767 0.0808105 24.9524 0.0808105C24.4281 0.0808105 23.9138 0.223892 23.4649 0.494634C23.0159 0.765376 22.6494 1.15351 22.4047 1.6172C15.0696 15.0156 7.72818 28.4141 0.396933 41.8125C-0.738224 43.882 0.708652 46.5649 2.94225 46.5649H46.9657C48.2032 46.5649 49.0344 45.8984 49.468 44.9945C50.0133 44.1336 50.1594 42.9906 49.511 41.8109" fill="#FFDD15"/>
								<path d="M22.5913 26.9352L21.8968 16.5742C21.7671 14.5563 21.7046 13.1063 21.7046 12.2266C21.7046 11.0281 22.0171 10.0961 22.6437 9.42345C23.2726 8.7547 24.0991 8.41876 25.1241 8.41876C26.3608 8.41876 27.1913 8.84923 27.6069 9.7047C28.0265 10.5656 28.239 11.8016 28.239 13.4164C28.239 14.3656 28.1874 15.3328 28.0843 16.3133L27.1546 26.9766C27.0538 28.2469 26.839 29.2203 26.5085 29.8977C26.1757 30.5781 25.6272 30.9141 24.8608 30.9141C24.0858 30.9141 23.5405 30.5867 23.2421 29.932C22.9351 29.275 22.7202 28.2758 22.5913 26.9352ZM24.9913 41.1672C24.1124 41.1672 23.346 40.8844 22.689 40.3133C22.0319 39.7445 21.7046 38.9477 21.7046 37.9242C21.7046 37.0313 22.0171 36.268 22.6437 35.6422C23.2726 35.0125 24.039 34.7 24.9491 34.7C25.8593 34.7 26.6312 35.0125 27.2749 35.6422C27.9116 36.268 28.239 37.0313 28.239 37.9242C28.239 38.932 27.9116 39.7274 27.2655 40.3024C26.6444 40.8684 25.8316 41.1775 24.9913 41.1672Z" fill="#1F2E35"/>
								</svg>
							</span>
							WARNING:
						</div> */}
						<div className="msg-text">
							<div>WARNING: This product contains nicotine.</div> 
							<div>Nicotine is an addictive chemical.</div>
						</div>
					</div>
				</div>
				{/* <nav className="navbar navbar-expand-lg navbar-light" role="navigation" aria-label="Main navigation">
					<TopLeft />
					<Menu data={menu} />
					{/* <TopRight/> 
				</nav> */}
				<nav>
					<Container>
						{/* <TopLeft /> */}
						<Menu data={menu} />
						{/* <TopRight/> */}
					</Container>
				</nav>
			</div>
			<Modal className="show modal-green-bg " show={ageVerificationPopupShow}>
				<Modal.Body>
					<Container>
						<Row className="mt-4 justify-content-center text-center">
							<Col xs={12} >
								<img className="leap-white-line-logo" src="/public/assets/images/leap_logo-text-white.png" />
							</Col>
							<Col xs={12} className="my-4">
								<div className="ghost-text">AGE VERIFICATION</div>
							</Col>
						</Row>
						<Row className="text-center">
							<Col xs={12} className="mt-3 mb-4">
								<div className="age-question">Are you 21+?</div>
							</Col>
							<Col xs={6} md={6}>
								<button className="btn btn-primary box-shadow" onClick={()=>handlePopupBtn("yes")}>Yes</button>
								<p className="mt-2">I am 21+</p>
							</Col>
							<Col xs={6} md={6}>
								<button className="btn btn-primary box-shadow" onClick={()=>handlePopupBtn("no")}>No</button>
								<p className="mt-2">I am NOT 21+</p>
							</Col>
							<Col xs={12} className="modal-note">
								<p className="mb-1"><strong>The products and services on this website are intended for adults only.</strong></p>
								<p>By entering this website, you certify that you are 21 years old or older.</p>
							</Col>
						</Row>
					</Container>
				</Modal.Body>
			</Modal>
		</Fragment>
	);
};

export default connect(null, { getManu })(Header);
