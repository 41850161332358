import React, { useState, useEffect } from 'react';
import { getUserDetails, updateUserDetails } from '../../redux/actions';
import { connect } from 'react-redux';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { TextField, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { camelToTitle } from './index';
import { DatePicker } from '@material-ui/pickers';
import { useDispatch } from 'react-redux';
import { REQUEST_INTAKE_SURVEY_SUBMITTED } from '../../redux/actions/types';

const useStyles = makeStyles(theme => ({
  cardWhiteBg: { background: '#fff !important' },
}));
const genderlist = [
  {
    value: 'm',
    label: 'Male',
  },
  {
    value: 'f',
    label: 'Female',
  },
  {
    value: 'na',
    label: 'No Choice',
  },
];
var validator = require('validator');
const RegistrationSurvey = ({ getUserDetails, updateUserDetails, setLoading }) => {
  const { t } = useTranslation();
  const confige = useSelector(state => state.app.config);
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [place, setPlace] = useState({});
  const [redirectUrl, setRedirectUrl] = useState('/');
  const [getApiCall, setGetApiCall] = useState(false);
  const classes = useStyles();
  /*[START:INITIALIZE_DECLARATION]*/
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    birth: '',
    homePhone: '',
    cellPhone: '',
    address: '',
    address2: '',
    country: '',
    state: '',
    suite: '',
    city: '',
    zip: '',
    shippingInstructions: '',
    gender: '',
    receiveEmail: '',
    receiveSms: '',
    companyName: '',
    instagramHandle: '',
    facebookHandle: '',
    tiktokHandle: '',
    twitterHandle: '',
    socialSecurityNumber: '',
  });
  /*[END:INITIALIZE_DECLARATION]*/
  const [underAge, setUnderAge] = useState(false);
  const [validation, setValidation] = useState({});
  const [error, setError] = useState({ firstName: '', email: '', homePhone: '', cellPhone: '', lastName: '', username: '', address: '', address2: '', country: '', state: '', suite: '', zip: '', city: '', shippingInstructions: '', receiveEmail: '', receiveSms: '', gender: '', instagramHandle: '', facebookHandle: '', tiktokHandle: '', twitterHandle: '', socialSecurityNumber: '' });
  //const [calender, setCalender] = useState(false);
  const [bonusPoints, setBonusPoints] = useState(0);
  useEffect(() => {
    if (confige.bonusPointInfo) {
      if (confige.bonusPointInfo.onProfileComplete) {
        setBonusPoints(confige.bonusPointInfo.onProfileComplete.points ? confige.bonusPointInfo.onProfileComplete.points : 0);
      }
    }
  }, [confige, confige.bonusPointInfo]);

  useEffect(() => {
    setLoading(true);
    getUserDetails(user.id)
      .then(response => {
        setLoading(false);
        if (response.data.birth) {
          response.data.birth = format(new Date(response.data.birth), 'MM-dd-yyyy');
        }
        if (response.data.firstName && response.data.lastName && response.data.gender) {
          dispatch({ type: REQUEST_INTAKE_SURVEY_SUBMITTED, payload: true });
        } else {
          setGetApiCall(true);
        }
        setData({ ...data, ...response.data });
      })
      .catch(error => {
        setLoading(false);
        setGetApiCall(true);
        console.log('error', error);
      });
    let items = {};
    if (confige.uiConfig) {
      confige.uiConfig.userFields.forEach(element => {
        if (['firstName', 'lastName', 'gender'].indexOf(element.name) >= 0) {
          element.validation.mandatory = true;
        }
        items[element.name] = element.validation;
      });
    }
    setValidation(items);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const redtUrl = query.get('route') ? query.get('route') : '/';
    if (redirectUrl !== redtUrl) {
      setRedirectUrl(redtUrl);
    }
    // eslint-disable-next-line
  }, [window.location]);
  const handleChange = e => {
    const { name, value } = e.target;
    let errorMessage = '';
    let validationArray = validation[name];
    let check = false;
    for (let key in validationArray) {
      if (key === 'minLength' && value.length !== 0) {
        if (value.length < validationArray[key]) {
          errorMessage = `${t('Should not be less then', { count: validationArray[key] })}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'maxLength') {
        if (value.length > validationArray[key]) {
          errorMessage = `${t('Should not be greater then', { count: validationArray[key] })}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'mandatory' && validationArray[key]) {
        if (!value.length) {
          errorMessage = `${camelToTitle(name)} ${t('is required')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'email' && validationArray[key]) {
        if (!validator.isEmail(value)) {
          errorMessage = `${t('Please enter a valid email')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'upperCaseReq' && validationArray[key]) {
        if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have atleast one Upper Case latter')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'lowerCaseReq' && validationArray[key] && value.length !== 0) {
        if (value.search(/[a-z]/) < 0) {
          errorMessage = `${t('Should have atleast one Lower Case latter')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'specialCharReq' && validationArray[key]) {
        // eslint-disable-next-line
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (!format.test(value) && value.length !== 0) {
          errorMessage = `${t('Should have atleast one special letter')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
    }
    let nameFormat = /[&\/\\#,+()$~`%'":.*?<>{}\[\]!@^;=_|0-9\s]/g;
    if (name === 'firstName' && nameFormat.test(value) && value.length !== 0) {
      setError({ ...error, [name]: 'First name must not contain any numeric digit and special character except the hyphen (-)' });
      check = true;
    }
    if (name === 'lastName' && nameFormat.test(value) && value.length !== 0) {
      setError({ ...error, [name]: 'Last name must not contain any numeric digit and special character except the hyphen (-)' });
      check = true;
    }
    if (!check) {
      setError({ ...error, [name]: '' });
    }
    setData({ ...data, [name]: value });
  };
  const onSubmit = e => {
    e.preventDefault();
    let errorArray = {};
    for (let name in data) {
      let value = data[name];
      let validationArray = validation[name];
      let errorMessage = '';

      let nameFormat = /[&\/\\#,+()$~`%'":.*?<>{}\[\]!@^;=_|0-9\s]/g;
      if (name === 'firstName' && nameFormat.test(value) && value.length !== 0) {
        errorMessage = 'First name must not contain any numeric digit and special character except the hyphen (-)';
      }
      if (name === 'lastName' && nameFormat.test(value) && value.length !== 0) {
        errorMessage = 'Last name must not contain any numeric digit and special character except the hyphen (-)';
      }
      if (name === 'birth' && isNaN(new Date(value)) && value.length !== 0) {
        errorMessage = `${t('Invalid')} ${t('Birth Date')}.`;
      } else if (name === 'birth') {
        errorMessage = '';
      }

      if (value !== null) {
        for (let key in validationArray) {
          if (key === 'email' && validationArray[key] && !validator.isEmail(value)) {
            errorMessage = `${t('Please enter a valid email')}.`;
          } else if (key === 'minLength' && value.length < validationArray[key] && value.length !== 0) {
            errorMessage = `${t('Should not be less then', { count: validationArray[key] })}.`;
          } else if (key === 'maxLength' && value.length > validationArray[key]) {
            errorMessage = `${t('Should not be greater then', { count: validationArray[key] })}.`;
          } else if (key === 'mandatory' && validationArray[key] && !value.length) {
            errorMessage = `${camelToTitle(name)} ${t('is required')}.`;
          } else if (key === 'upperCaseReq' && value.search(/[A-Z]/) < 0 && value.length !== 0) {
            errorMessage = `${t('Should have atleast one Upper Case latter')}.`;
          } else if (key === 'lowerCaseReq' && value.search(/[a-z]/) < 0 && value.length !== 0) {
            errorMessage = `${t('Should have atleast one Lower Case latter')}.`;
          } else if (key === 'specialCharReq' && value.length !== 0) {
            // eslint-disable-next-line
            var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            if (!format.test(value)) {
              errorMessage = `${t('Should have atleast one special letter')}.`;
            }
          }
        }
        errorArray[name] = errorMessage;
      }
    }
    console.log('errorArray', errorArray);
    setError(errorArray);
    let check = false;
    for (let key in errorArray) {
      if (errorArray[key]) {
        check = true;
      }
    }
    if (!check) {
      setLoading(true);
      updateUserDetails(user.id, data)
        .then(response => {
          if (response.httpCode === 201) {
            toast.success(`Thanks for coming back! You’ve earned ${bonusPoints} points.`);
          } else {
            toast.success(`Successfully updated your profile.`);
          }
          history.push(redirectUrl);
          dispatch({ type: REQUEST_INTAKE_SURVEY_SUBMITTED, payload: true });
          setLoading(false);
        })
        .catch(error => {
          //console.log(error);
          let errorMessage = 'Something went wrong!';
          if (Array.isArray(error)) {
            error.forEach(item => {
              if (item.type && item.message) {
                errorMessage = errorMessage + ` ${item.type} - ${Array.isArray(item.message) ? item.message.join(',') : item.message}`;
              }
            });
          }
          toast.error(errorMessage);
          setLoading(false);
        });
    }
  };

  const handleDate = value => {
    setData({ ...data, birth: format(value, 'MM-dd-yyyy') });
  };
  useEffect(() => {
    if (place.hasOwnProperty('address')) {
      let curStateData = Object.assign({}, data);
      if (curStateData.hasOwnProperty('address')) {
        curStateData.address = place.address;
      }
      // if (curStateData.hasOwnProperty("country")) {
      // 	curStateData.country = place.country;
      // }
      if (curStateData.hasOwnProperty('state')) {
        curStateData.state = place.state;
      }
      if (curStateData.hasOwnProperty('city')) {
        curStateData.city = place.city;
      }
      // if (curStateData.hasOwnProperty("suite")) {
      // 	curStateData.suite = place.street;
      // }
      if (curStateData.hasOwnProperty('zip')) {
        curStateData.zip = place.zip;
      }
      setData(curStateData);
    }
    // eslint-disable-next-line
  }, [place]);
  const googleSelectedAddressHandler = place => {
    setPlace(place);
  };
  const formatChars = {
    '1': '[12]',
    '0': '[0123456789]',
  };
  const [selectedDate, handleDateChange] = useState(new Date());
  return (
    getApiCall && (
      <div>
        <div className="page-heading profile-img">
          <div className="intro-welcome">
            <h1 className="survey-heading">REGISTRATION INTAKE SURVEY</h1>
          </div>
        </div>
        <div className="welcomepage">
          <div className="container-fluid">
            <div className={`card ${classes.cardWhiteBg}`}>
              <p className="sub-text">Please update these details.</p>
              <form role="form" method="post">
                <div className="row mt-5">
                  {/*[START:FIRSTNAME]*/}
                  <div className="form-group col-md-6">
                    <TextField variant="filled" name="firstName" type="text" id="first-name" label={`${t('lang_key_fname')}`} value={data.firstName !== null ? data.firstName : ''} onChange={handleChange} helperText={error.firstName} error={error.firstName ? true : false} aria-required="true" autoComplete="name" required />
                    {/* {validation["firstName"] && validation["firstName"].mandatory ? <Required /> : ""} */}
                  </div>
                  {/*[END:FIRSTNAME]*/}

                  {/*[START:LASTNAME]*/}
                  <div className="form-group col-md-6">
                    <TextField variant="filled" name="lastName" type="text" id="last-name" label={`${t('lang_key_lname')}`} value={data.lastName !== null ? data.lastName : ''} onChange={handleChange} helperText={error.lastName} error={error.lastName ? true : false} aria-required="true" autoComplete="family-name" required />
                  </div>
                  {/*[END:LASTNAME]*/}
                </div>
                <div className="row  mt-4">
                  {/*[START:ZIP]*/}
                  <div className="form-group col-md-4">
                    <TextField variant="filled" label="Zip Code" name="zip" onChange={handleChange} value={data.zip !== null ? data.zip : ''} id="zip" helperText={error.zip} error={error.zip ? true : false} required />
                  </div>
                  {/*[END:ZIP]*/}
                  {/*[START:BIRTHDATE]*/}
                  <div className="form-group col-md-4 col-sm-4">
                    <DatePicker
                      variant="inline"
                      inputVariant="filled"
                      label="Birth Date"
                      name="dobenter"
                      // onChange={handleDate}
                      value={data.birth ? new Date(data.birth) : null}
                      id="dobenter"
                      helperText={error.birth}
                      error={error.birth}
                      // required
                      disableFuture
                      autoOk
                      maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
                      disabled={underAge}
                      // formatChars={formatChars}
                      format="dd-MM-yyyy"
                      onChange={date => {
                        if (!underAge) handleDate(date);
                      }}
                      className="show-placegholder"
                    />
                  </div>
                  {/*[END:BIRTHDATE]*/}
                  {/*[START:GENDER]*/}
                  <div className="form-group col-md-4">
                    <TextField variant="filled" select label={t('Gender')} name="gender" onChange={handleChange} value={data.gender !== null ? data.gender : ''} id="gender" helperText={error.gender} error={error.gender ? true : false} required>
                      {genderlist.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  {/*[END:GENDER]*/}

                  <div className="col-md-12 text-center mt-5">
                    <button type="submit" role="submit" id="profile-submit-btn" onClick={onSubmit} className="btn btn-primary">
                      Save Changes
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default connect(null, { getUserDetails, updateUserDetails })(IsLoadingHOC(RegistrationSurvey, 'Wait .....'));
