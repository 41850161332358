import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Button, Card, Modal } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { useSelector, useDispatch } from 'react-redux';
import ViewOrder from '../../components/rewards/ViewOrder';
import GooglePlaces from '../../components/GooglePlaces';
import { Toolbar, Typography } from '@material-ui/core';
const VIPRewardsPreview = props => {
  const { t } = useTranslation();
  const user = useSelector(({ user }) => user);
  const [data, setDate] = useState({});
  const parseJwt = token => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };
  useEffect(() => {
    let token = user.accessToken;
    let tier = 0;
    let tokenData = parseJwt(token);
    if (tokenData) {
      tier = parseInt(tokenData.tier);
    }
    setDate({
      itemId: 'vip-customer-support',
      balance: 100,
      redeemPoints: 0,
      id: 'vip-customer-support',
      downloadable: false,
      vendor: 'VVS',
      image: '/public/rewards/vip-support.jpg',
      itemDescription: tier > 3 ? `Welcome to VIP Support! As a Leap® Smart Rewards Genius, you now have access to your own dedicated Leap representative. They will be available to help answer any and all Leap Smart Rewards or Leap product questions you may have.` : 'This feature is only available for Genius Members. Please earn more points to unlock VIP Customer Support.',
      itemName: 'VIP Customer Support',
      type: 'VVS',
      redeemPrice: 0,
      className: tier > 3 ? 'border-bottom' : 'lockItem',
      userTier: tier,
    });
  }, []);
  return (
    <>
      <div className="page-heading rewardBackImg">
        <div className="overlay-mask">
          <h1>VIP Customer Support</h1>
        </div>
      </div>
      <Container className="rewards-list-container">
        <div className="post-login">
          <Row xs={1} md={2} lg={2}>
            <Col>
              <Card className="mx-sm-5 mx-2">
                <Card.Img variant="top" src={data.image} />
              </Card>
            </Col>
            <Col className="px-sm-5 px-2 reward-details">
              <Toolbar>
                <Typography variant="h6">Points Required: {data.redeemPoints}</Typography>
              </Toolbar>
              <h4>{data['itemName'] ? data.itemName : ''}</h4>
              <h5 className="description">{data['itemDescription'] ? data.itemDescription : ''}</h5>
              {data.userTier > 3 && (
                <>
                  <h5 className="description pt-5">Your personal Leap representative is: LaVonna</h5>
                  <h6 className="h6-white pt-2">
                    <span className="material-icons">call</span> <a href="tel:+18052914623">1-805-291-4623</a> | Monday – Friday, 9 AM to 5 PM ET
                  </h6>
                  <h6 className="h6-white pt-2">
                    <span className="material-icons">email</span> <a href="mailto:GeniusSupport@LeapVapor.com?subject=VIP Smart Rewards Inquiry">GeniusSupport@LeapVapor.com</a> | 24/7
                  </h6>
                </>
              )}
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
export default IsLoadingHOC(VIPRewardsPreview, 'Loading....');
