import React from 'react';
import { getManu } from '../../redux/actions';
import { connect } from 'react-redux';
import { footerMenu } from '../../data';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagramSquare } from '@fortawesome/free-brands-svg-icons';
import CopyRight from '../../components/CopyRight';
import { useSelector } from 'react-redux';
//import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
const Footer = () => {
  //const { t } = useTranslation();
  const user = useSelector(({ user }) => user);
  const pushDataLayer = menu => {
    let url = `${window.location.pathname}${window.location.search ? `&${window.location.search}` : ''}`;
    if (window.gtmDataLayer) {
      window.gtmDataLayer.push({
        event: 'element_clicked',
        elementType: 'FLink',
        path: url,
        userId: user?.id || '',
        email: user?.email || '',
        username: user?.username || '',
        name: user?.name || '',
        refId: '',
        refType: '',
        elementTag: menu,
        createdDate: new Date(),
      });
    }
  };
  return (
    <footer>
      <Container className="footer">
        <div className="row my-2">
          <div className="col-12 col-md-3 d-none d-lg-block footer-logo_wrap">
            <img src="/public/assets/images/leap_rewards.png" alt="Leap Vapor Bottom Logo" />
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-9 px-2 px-sm-3">
            <div className="footer-link footer_nav text-center justify-content-center">
              {user.accessToken ? (
                <ul className="d-none d-sm-inline-block text-center justify-content-center">
                  <li>
                    <Link to="/earn-points/pincode" onClick={() => pushDataLayer('submit_code-flink')}>
                      SUBMIT CODE
                    </Link>
                  </li>
                  <li>
                    <span className="pipe-separate">|</span>
                  </li>
                  <li>
                    <Link to="/rewards" onClick={() => pushDataLayer('rewards-flink')}>
                      REDEEM REWARDS
                    </Link>
                  </li>
                  <li>
                    <span className="pipe-separate">|</span>
                  </li>
                  <li>
                    <Link to="/earn-points" onClick={() => pushDataLayer('earn_points-flink')}>
                      EARN POINTS
                    </Link>
                  </li>
                  <li>
                    <span className="pipe-separate">|</span>
                  </li>
                  <li>
                    <Link to="/contest" onClick={() => pushDataLayer('contest-flink')}>
                      CONTEST
                    </Link>
                  </li>
                  <li>
                    <span className="pipe-separate">|</span>
                  </li>
                  <li>
                    <a href="https://www.leapvapor.com/store-locator/" target="_blank" onClick={() => pushDataLayer('find_a_store-flink')}>
                      FIND A STORE
                    </a>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>
                    <Link to="/login" onClick={() => pushDataLayer('login-flink')}>
                      LOGIN
                    </Link>
                  </li>
                  <li>
                    <span className="pipe-separate">|</span>
                  </li>
                  <li>
                    <Link to="/signup" onClick={() => pushDataLayer('register-flink')}>
                      REGISTER
                    </Link>
                  </li>
                </ul>
              )}
            </div>
            <div className="footer-link">
              <ul>
                <li>
                  <Link to="/public/assets/doc/Leap Rewards_Terms & Conditions_07-28-2022.pdf" target="_blank" onClick={() => pushDataLayer('terms_conditions-flink')}>
                    TERMS & CONDITIONS
                  </Link>
                </li>
                <li>
                  <span className="pipe-separate">|</span>
                </li>
                <li>
                  <Link to="/public/assets/doc/privacypolicy.pdf" target="_blank" onClick={() => pushDataLayer('privacy_policy-flink')}>
                    PRIVACY POLICY
                  </Link>
                </li>
              </ul>
            </div>
            <div className="mt-3 text-center">
              <p>
                <strong className="bold">UNDERAGE SALE PROHIBITED.</strong> California Proposition 65. &nbsp;
                <span className="footer-warning-icon">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15.8427 13.38C13.4957 9.09252 11.1477 4.80502 8.8002 0.517524C8.72191 0.369143 8.60462 0.24494 8.46096 0.158302C8.31729 0.0716651 8.15271 0.0258789 7.98494 0.0258789C7.81718 0.0258789 7.6526 0.0716651 7.50893 0.158302C7.36527 0.24494 7.24798 0.369143 7.16969 0.517524C4.82244 4.80502 2.47319 9.09252 0.127194 13.38C-0.236056 14.0423 0.226944 14.9008 0.941694 14.9008H15.0292C15.4252 14.9008 15.6912 14.6875 15.8299 14.3983C16.0044 14.1228 16.0512 13.757 15.8437 13.3795"
                      fill="#FFDD15"
                    />
                    <path
                      d="M7.22906 8.61922L7.00681 5.30372C6.96531 4.65797 6.94531 4.19397 6.94531 3.91247C6.94531 3.52897 7.04531 3.23072 7.24581 3.01547C7.44706 2.80147 7.71156 2.69397 8.03956 2.69397C8.43531 2.69397 8.70106 2.83172 8.83406 3.10547C8.96831 3.38097 9.03631 3.77647 9.03631 4.29322C9.03631 4.59697 9.01981 4.90647 8.98681 5.22022L8.68931 8.63247C8.65706 9.03897 8.58831 9.35047 8.48256 9.56722C8.37606 9.78497 8.20056 9.89247 7.95531 9.89247C7.70731 9.89247 7.53281 9.78772 7.43731 9.57822C7.33906 9.36797 7.27031 9.04822 7.22906 8.61922ZM7.99706 13.1735C7.71581 13.1735 7.47056 13.083 7.26031 12.9002C7.05006 12.7182 6.94531 12.4632 6.94531 12.1357C6.94531 11.85 7.04531 11.6057 7.24581 11.4055C7.44706 11.204 7.69231 11.104 7.98356 11.104C8.27481 11.104 8.52181 11.204 8.72781 11.4055C8.93156 11.6057 9.03631 11.85 9.03631 12.1357C9.03631 12.4582 8.93156 12.7127 8.72481 12.8967C8.52605 13.0778 8.26595 13.1768 7.99706 13.1735Z"
                      fill="#1F2E35"
                    />
                  </svg>
                </span>
                <strong className="bold">WARNING:</strong> This product can expose you to chemicals including nicotine, which is known to the State of California to cause birth defects or other reproductive harm. For more information, go to{' '}
                <a className="text-link" href="https://www.p65warnings.ca.gov/" target="_blank" onClick={() => pushDataLayer('P65Warnings-flink')}>
                  <strong className="bold">www.P65Warnings.ca.gov</strong>
                </a>
                .
              </p>
            </div>
            <CopyRight pcallBack={pushDataLayer} />
          </div>
        </div>
      </Container>
    </footer>
  );
};
export default connect(null, { getManu })(Footer);
