export const USER_DATA_SAVE = "USER_DATA_SAVE";
export const LOG_OUT = "LOG_OUT";
export const REQUEST_INTAKE_SURVEY_SUBMITTED = "REQUEST_INTAKE_SURVEY_SUBMITTED"
export const SAVE_USER_CONFIG = "SAVE_USER_CONFIG";
export const SAVE_ACCESS_TOKEN = "SAVE_ACCESS_TOKEN";
export const SAVE_REFRESH_TOKEN = "SAVE_REFRESH_TOKEN";

export const EARNPOINT_VIDEO_PREVIEW = "EARNPOINT_VIDEO_PREVIEW";
export const USER_POINT_BALANCE_SAVE = "USER_POINT_BALANCE_SAVE";
export const EARNPOINT_VIDEO_PLAYSTATUS = "EARNPOINT_VIDEO_PLAYSTATUS";

export const SURVEY_DETAIL = "SURVEY_DETAIL";

export const NOTIFICATION_CENTER_CREATE = "NOTIFICATION_CENTER_CREATE";
export const NOTIFICATION_CENTER_UPDATE = "NOTIFICATION_CENTER_UPDATE";

export const NOTIFICATION_CENTER_POPUP_CREATE = "NOTIFICATION_CENTER_POPUP_CREATE";
export const NOTIFICATION_CENTER_POPUP_UPDATE = "NOTIFICATION_CENTER_POPUP_UPDATE";

export const CURRENT_SELECTED_MENU = "CURRENT_SELECTED_MENU";
