import React from 'react';
import { Container } from 'react-bootstrap';
//import {footerCopyRight} from "../data";
import { useTranslation } from 'react-i18next';

const CopyRight = props => {
  const { t } = useTranslation();
  return (
    <div className="footer-bot">
      <div className="row">
        <div className="col-12">
          <div className="copy-text">
            <p className="mr-2">
              {' '}
              ©{new Date().getFullYear()} {t('copyrightText')}
              <span className="tierlogic">
                {t(' Powered By ')}
                <a
                  href="https://www.3tierlogic.com/"
                  target={'_blank'}
                  onClick={() => {
                    if (props.pcallBack) {
                      props.pcallBack('3tierlogic-flink');
                    }
                  }}>
                  <img alt="3TL Logo" src="/public/assets/images/3tl_col.png" />
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CopyRight;
