import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col, Form, InputGroup,  Modal, Button } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { Link } from "react-router-dom";
import { validateCodeAction, balanceGetAction } from "../../redux/actions";
import Captcha from "../CaptchaClick";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Required from "../Required";
import {  TextField, createMuiTheme, FormControl, Select, MenuItem, InputLabel, } from "@material-ui/core";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
const formLabelsTheme = createMuiTheme({
	overrides: {
		MuiFormLabel: {
			asterisk: {
				color: "#db3131",
				"&$error": {
					color: "#db3131",
				},
			},
		},
	},
});

const Pincode = (props) => {
	const { t } = useTranslation();
	let dispatch = useDispatch();
	const config = useSelector((state) => state.app.config);
	let [codelist, setCodeList] = useState([""]);
	let [codeStatusList, setcodeStatusList] = useState([]);
	let [showPopup, setShowPopup] = useState(false);
	let [captchaValue, setCaptchaValue] = useState(false);
	let [resetCaptcha, setResetCaptcha] = useState(0);
	let [captchaEnable, setCaptchaEnable] = useState(false);
	const codeListUpdate = (ind) => {
		let codeTextFiled = [...codelist];
		if (ind === codeTextFiled.length - 1) {
			codeTextFiled.push("");
		} else {
			codeTextFiled.splice(ind, 1);
		}
		setCodeList(codeTextFiled);
	};
	React.useEffect(() => {
		if (config.captchaConfig) {
			if (config.captchaConfig.packageCodeCaptcha) {
				setCaptchaEnable(true);
			}
		}
		//eslint-disable-next-line
	}, [config]);
	const updateCodeList = (e, ind) => {
		let codeTextFiled = [...codelist];
		// if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
		codeTextFiled[ind] = e.target.value;
		// }
		setCodeList(codeTextFiled);
	};
	const resetComponent = () => {
		let tempresetCaptcha = resetCaptcha + 1;
		setResetCaptcha(tempresetCaptcha);
		setCodeList([""]);
		setcodeStatusList([]);
		setCaptchaValue(false);
	};
	const formSubmit = () => {
		let codeTextFiled = [...codelist];
		if (codeTextFiled[0] === "") {
			toast.error(`${t("Error")}! ${t("Please try again")}. ${t("Enter at least one code")}.`);
			return false;
		}
		if (!captchaValue && captchaEnable) {
			toast.error(`${t("Error")}! ${t("Please select captcha")}.`);
			return false;
		}
		let submitCodes = codeTextFiled.filter((v) => v !== "");
		props.setLoading(true);
		let formData = { packageCodes: submitCodes };
		if (captchaEnable) {
			formData["captchaVal"] = captchaValue;
		}
		validateCodeAction(formData)
			.then((responseData) => {
				if (responseData.httpCode === 200) {
					setcodeStatusList(responseData.data);
					dispatch(balanceGetAction());
				} else {
					toast.error(`${t("Error")}! ${t("Something went wrong")}.`);
					console.log("responseData", responseData);
				}
				props.setLoading(false);
			})
			.catch((err) => {
				toast.error(`${t("Error")}! ${t("Something went wrong")}.`);
				props.setLoading(false);
				console.log("Response Error:", JSON.stringify(err));
			});
	};
	return (
		<div>
			<div className="page-heading smoke-bg">
				<div className="overlay-mask">
					<h1 className="bold">Submit Code</h1>
				</div>
			</div>
			<div className=" submit-page">
				<Container>
					<Row className="justify-content-center">
						<Col sm="7" className="text-center">
							<div className="dark-bg submit-content-bg dark-border">
								<div className="px-sm-5">
								<h2 className="pt-3">Upload the code found on your Yellow Sticker, Pod Pack and Leap Device</h2>
								</div>
								<img src="/public/assets/images/leap_device_kit.png" className="col my-3 px-0" alt="Leap Vapor Device Kit, Carolina Tabacco" />
								<div  className="px-sm-5 mt-3">
									<div className="row justify-content-center">
										
										{codelist.map((v, i) => {
											return (
												<div key={`row${i}`} className="addproductcode col-sm-10">
													{/*[START:Sticker code]*/}
													<div className="form-group col-sm-12 px-0 mb-4">
														<TextField 
															variant="filled" 
															id="yScode" 
															label="Enter Yellow Sticker code"
															// helperText="error text" 
															// error="error text line"
															// value={v} 
															// onChange={handleChange} 
															aria-required="true" 
															name="yScode" 
															maxLength={100}
															required 
														/>
														
													</div>
													{/*[END:Sticker code]*/}

													{/*[START:Pod Pack code]*/}
													<div className="form-group col-sm-12 px-0 mb-4">
														<TextField 
															variant="filled" 
															id="podpackcode" 
															label="Enter Pod Pack code"
															// helperText="error text" 
															// error="error text line"
															// value={v} 
															// onChange={handleChange}
															aria-required="true" 
															name="podpackcode" 
															maxLength={100}
															required 
														/>
													</div>
													{/*[END:Pod Pack code]*/}

													{/*[START:Pod Pack code]*/}
													<div className="form-group col-sm-12 px-0 mb-4">
														<TextField 
															variant="filled" 
															id="leapdevicecode" 
															label="Enter Leap Device code"
															// helperText="error text" 
															// error="error text line"
															// value={v} 
															// onChange={handleChange}
															aria-required="true" 
															name="leapdevicecode" 
															maxLength={100}
															required 
														/>
													</div>
													{/*[END:Pod Pack code]*/}
													
												</div>
												
											);
										})}
										
									</div>
									{captchaEnable ? (
										<div className="justify-content-md-center">
											<Captcha reset={resetCaptcha} parentCallback={setCaptchaValue} />
										</div>
									) : (
										""
									)}
											
								</div>
								<div  className="px-sm-5 mt-3">
									<div className="btn-div pb-4 px-40">
										<button type="button" onClick={formSubmit} className="btn btn-primary btn-submit w-100" id="submit-code-btn">
											<Trans>Submit</Trans>
										</button>

										<p className="text-center mt-4">Reward can only be redeemed once</p>
									</div>
								</div>
							</div>
						</Col>
					</Row>					
				</Container>
				
			</div>
		</div>
	);
};

export default IsLoadingHOC(Pincode, "Loading....");
