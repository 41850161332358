import React, { useEffect, useState } from 'react';
import Card from '../Card';
import { rewardsListAction, balanceGetAction } from '../../redux/actions';
import { Button, Container, Col, Row } from 'react-bootstrap';
import IsLoadingHOC from '../IsLoadingHOC';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Typography } from '@material-ui/core';

const RewardsList = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const pageLimit = 8;
  const [pageNo, setPageNo] = useState(0);
  const [viewPaginationBtn, setViewPaginationBtn] = useState(false);
  const [data, setData] = useState([]);
  const [rewardsSelectBox, setRewardsSelectBox] = useState('');
  let [rewardListData, setRewardListData] = useState([]);
  const [pageRedirect, setPageRedirect] = useState(false);
  const parseJwt = token => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };
  const convertPointsFormat = (points, inActual) => {
    if (inActual) {
      return points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      if (points >= 1000) {
        points = points % 1000 <= 49 ? `${(points / 1000).toFixed(0)}K` : `${(points / 1000).toFixed(1)}K`;
      }
      return points;
    }
  };
  const [rewardsTypeList, setRewardsTypeList] = useState([]);

  useEffect(() => {
    dispatch(balanceGetAction());
    getRewardsList();
    // eslint-disable-next-line
  }, []);
  const getRewardsList = () => {
    props.setLoading(true);
    setPageNo(0);
    let token = user.accessToken;
    let tier = 0;
    let tokenData = parseJwt(token);
    if (tokenData) {
      tier = parseInt(tokenData.tier);
    }
    rewardsListAction()
      .then(response => {
        if (response.data) {
          response.data.unshift({
            itemId: 'vip-customer-support',
            balance: 100,
            redeemPoints: 0,
            id: 'vip-customer-support',
            downloadable: false,
            vendor: 'VVS',
            image: tier > 3 ? '/public/rewards/vip-support.jpg' : '/public/rewards/unlock.png',
            itemDescription: 'This feature is only available for Genius Members. Please earn more points to unlock VIP Customer Support.',
            itemName: 'VIP Customer Support',
            type: 'VVS',
            redeemPrice: 0,
            className: tier > 3 ? 'border-bottom' : 'lockItem',
            userTier: tier,
          });
          let rewards = response.data.map(v => ({ ...v, redeemPointsInK: convertPointsFormat(v.redeemPoints, false) }));
          let rewardsType = [...new Set(rewards.map(v => v.type))];
          setRewardListData(rewards);
          setRewardsTypeList(rewardsType);
        }
        props.setLoading(false);
      })
      .catch(ex => {
        console.log('Error In API Call', ex);
        props.setLoading(false);
      });
  };
  const cardClick = async (redirectUrl, redeemPoints) => {
    if (user.pointBalance < redeemPoints) {
      return false;
    }
    setPageRedirect(redirectUrl);
  };
  const showActualPoints = (index, show) => {
    const list = JSON.parse(JSON.stringify(data));
    if (show) {
      list[index].redeemPointsInK = convertPointsFormat(list[index].redeemPoints, true);
    } else {
      if (list[index].redeemPoints >= 1000) {
        list[index].redeemPointsInK = convertPointsFormat(list[index].redeemPoints, false);
      }
    }
    setData(list);
  };
  const getNextPageData = () => {
    let tempRewardList = JSON.parse(JSON.stringify(rewardListData));
    if (rewardsSelectBox !== '') {
      tempRewardList = tempRewardList.filter(v => v.type === rewardsSelectBox);
    }
    let pageData = tempRewardList.slice(0, pageNo * pageLimit + pageLimit);
    setData(pageData);
    if (pageNo * pageLimit + pageLimit >= tempRewardList.length) {
      setViewPaginationBtn(false);
    } else {
      setViewPaginationBtn(true);
    }
  };
  const filterRewardList = async e => {
    setPageNo(0);
    setRewardsSelectBox(e.target.value);
  };
  useEffect(() => {
    getNextPageData();
    // eslint-disable-next-line
  }, [rewardListData, rewardsSelectBox, pageNo]);
  if (pageRedirect) {
    return <Redirect push to={pageRedirect} />;
  } else {
    return (
      <div>
        <div className="">
          <div className="page-heading profile-img">
            <img src="/public/assets/images/rewards.jpg" alt="Redeem Your Leap® Smart Rewards" />
            <div className="overlay-mask">
              <h1>Redeem Your Leap® Smart Rewards Points</h1>
              <p>Unlock new rewards by earning more points and leveling up</p>
            </div>
          </div>
        </div>
        <div className="">
          <Container fluid>
            <div className="post-login">
              <Container fluid className="rewards-list-container">
                {/* <Row>
									<Col>
										<div className="form-group filter-select rewards-category-div">
											<label htmlFor="rewardsCategory" className="mt-2">
												<Trans>Filter</Trans> 
											</label>
											<select className="form-control" id="rewardsCategory" value={rewardsSelectBox} onChange={filterRewardList}>
												<option value="">{`${t("Show")} ${t("ALL")}`}</option>
												{rewardsTypeList.map((v, i) => {
													return (
														<option key={i + "_typeOption"} value={v}>
															{v}
														</option>
													);
												})}
											</select>
										</div>
									</Col>
								</Row> */}
                <div className="rewards-list-body">
                  <Row xs={1} md={2} lg={4} className="card_holder activity-box">
                    {data.map((item, index) => {
                      return (
                        <Col key={index}>
                          <Card>
                            <div
                              onMouseOver={e => {
                                showActualPoints(index, true);
                              }}
                              onMouseLeave={e => {
                                showActualPoints(index, false);
                              }}
                              onClick={e => cardClick(`/reward-preview/${item.id}`, item.redeemPoints)}
                              className={(user.pointBalance >= item.redeemPoints ? ' ' : 'activity-block-disable ') + 'activity-block'}>
                              <div className={`activity-img-holder  d-flex align-items-center ${item?.className}`}>
                                <img src={item.image} alt="Avatar" />
                              </div>
                              <div className="card-body">
                                <div className="activity-info">
                                  <h5>{item.itemName}</h5>
                                </div>
                                <div className="row justify-content-center">
                                  <Button disabled={user.pointBalance >= item.redeemPoints ? '' : true} rewards-item={item.itemId} id={`redeem-btn-${item.id}`} className="cart-button col-10">
                                    {item.type === 'VVS' ? 'Learn More' : user.pointBalance >= item.redeemPoints ? t('Redeem Now') : t('More Points Required')}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
                <div className="text-center w-100">
                  {viewPaginationBtn ? (
                    <Button variant="secondary" id="view-more-earn-pointlist-btn" className="viewMore" onClick={() => setPageNo(pageNo + 1)}>
                      <Trans>View More</Trans> <Trans>Rewards</Trans>
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
                <div className="mt-4 text-center">
                  <p className="py-0">Award of Choice Digital Reward has been discontinued as of March 30, 2022.</p>
                </div>
              </Container>
            </div>
          </Container>
        </div>
      </div>
    );
  }
};

export default IsLoadingHOC(RewardsList, 'Loading....');
