import React from 'react';
import PortalPointList from '../../components/PortalPointList';
import Contests from '../../components/Contests';
import { useSelector } from 'react-redux';
import { Container, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import RegistrationSurvey from '../Profile/RegistrationSurvey';
const Home = props => {
  const user = useSelector(({ user }) => user);

  if (user.accessToken && !user.requestIntakeSurveySubmitted) {
    return <RegistrationSurvey />;
  } else if (user.accessToken) {
    return (
      <div>
        <div className="loggedin__banner-home">
          {/* <a href="/earn-points">
            <img className="w-100 d-none d-sm-block" alt="Earn points by taking our new quizzes" src="/public/assets/images/loggedin_desktop.png" />
            <img className="w-100 d-sm-none" alt="Earn points by taking our new quizzes" src="/public/assets/images/loggedin_mobile.png" />
          </a> */}
          <a href="/contest">
            <img className="w-100 d-none d-sm-block" alt="Earn points by taking our new quizzes" src="/public/assets/images/Leap_Banner_SlotMachine_March2023-100.png" />
            <img className="w-100 d-sm-none" alt="Earn points by taking our new quizzes" src="/public/assets/images/Leap_Mobile_SlotMachine_March2023-100.png" />
          </a>
        </div>
        <div>
          {/* steps */}
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-md-9 col-sm-12 text-center my-5">
                <h1>
                  WELCOME TO <strong>LEAP® SMART REWARDS</strong>
                </h1>
                <p>Reward yourself with the satisfaction of Leap and earn points on every purchase. Submit the codes found in Leap vapor products for exciting rewards, enter giveaways and sweepstakes, and level up to unlock exclusive perks and prizes.</p>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row mb-5 text-center justify-content-center">
              <div className="col-sm-3">
                <div className="home-icon-step">
                  <img className="w-100" src="/public/assets/images/icons/1_green.png" alt="BUY LEAP" />
                </div>
                <h3 className="h3">BUY LEAP</h3>
                <p className="px-3">Vape on your terms with the Leap Device and Leap Go®.</p>
              </div>
              <div className="col-sm-3">
                <div className="home-icon-step">
                  <img className="w-100" src="/public/assets/images/icons/2_green.png" alt="EARN POINTS" />
                </div>
                <h3 className="h3">EARN POINTS</h3>
                <p className="px-3">Enter codes, take surveys, refer friends, and more.</p>
              </div>
              <div className="col-sm-3">
                <div className="home-icon-step">
                  <img className="w-100" src="/public/assets/images/icons/3_green.png" alt="LEVEL UP" />
                </div>
                <h3 className="h3">LEVEL UP</h3>
                <p className="px-3">The more points you earn, the better your rewards.</p>
              </div>
              <div className="col-sm-3">
                <div className="home-icon-step">
                  <img className="w-100" src="/public/assets/images/icons/4_green.png" alt="SMART CHOICE" />
                </div>
                <h3 className="h3">SMART CHOICE</h3>
                <p className="px-3">Stay satisfied and continue to enjoy Leap.</p>
              </div>
            </div>
          </div>
          {/* end steps */}
          {/* faq */}
          <div className="container-fluid home-faq">
            <div className="mt-5">
              <div className="row bg-green">
                <div className="col-sm-6 text-center">
                  <img src="/public/assets/images/faq.png" className="faqimg text-center" alt="leap vapor kits" />
                </div>
                <div className="col-sm-6">
                  <h1 className="mt-5">FREQUENTLY ASKED QUESTIONS</h1>
                  <div className="mb-4">
                    <div id="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            <div className="row">
                              <div className="col-11">
                                <h5 className="mb-0">What is leap® smart rewards?</h5>
                              </div>
                              <div className="col-1">
                                <span className="material-icons float-right show-more">expand_more</span>
                                <span className="material-icons float-right show-less">expand_less</span>
                              </div>
                            </div>
                          </button>
                        </div>
                        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                          <div className="card-body">Leap Smart Rewards is a loyalty program that allows Leap customers to earn points towards exciting prizes and entries into giveaway and sweepstakes.</div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <div className="row">
                              <div className="col-11">
                                <h5 className="mb-0">How do I earn Leap® Smart Rewards points?</h5>
                              </div>
                              <div className="col-1">
                                <span className="material-icons float-right show-more">expand_more</span>
                                <span className="material-icons float-right show-less">expand_less</span>
                              </div>
                            </div>
                          </button>
                        </div>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                          <div className="card-body">You can earn points for all sorts of activities, including uploading codes found in Leap products, taking surveys, referring friends, and more.</div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            <div className="row">
                              <div className="col-11">
                                <h5 className="mb-0">What rewards can I earn with Leap® Smart Rewards points?</h5>
                              </div>
                              <div className="col-1">
                                <span className="material-icons float-right show-more">expand_more</span>
                                <span className="material-icons float-right show-less">expand_less</span>
                              </div>
                            </div>
                          </button>
                        </div>
                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                          <div className="card-body">Leap Smart Rewards points can be redeemed for digital gift cards, with new rewards coming soon.</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end faq */}
          {/* CTA community */}
          <div className="join-community">
            <div className="page-heading">
              <img src="/public/assets/images/cta.jpg" alt="JOIN THE COMMUNITY" />
              <div className="overlay-mask">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-6">
                      <h1 className="bold">JOIN THE COMMUNITY</h1>
                      <button className="btn btn-primary-inverse">ENTER REWARDS</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END CTA community */}
          {/* EARN POINTS */}
          <div className="eranpoint-home">
            <Container fluid>
              <h2 className="mt-5 theme-color text-center">
                EARN POINTS BY <strong>COMPLETING ACTIVITIES</strong>
              </h2>
            </Container>
            <Container>
              <div className="post-login">
                <Row xs={1} md={2} lg={4} className="card_holder activity-box">
                  <PortalPointList pagesize={4} viewPageBtn={true} isHome={true} />
                </Row>
              </div>
            </Container>
          </div>
          {/* END EARN POINTS */}
        </div>
      </div>
    );
  } else {
    return (
      <div className="home-banner-img">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-10 col-md-6">
              <div className="carousel-caption">
                <h1>
                  BUY LEAP®.
                  <br />
                  EARN POINTS.
                  <br />
                  <span>GENIUS.</span>
                </h1>
                <div className="justify-content-center">
                  <a className="btn btn-primary" href="/signup">
                    Create Account
                  </a>
                  <a className="btn btn-primary" href="/login">
                    Login Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Home;
