import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { notificationStatusUpdateAction, notificationGetAction } from '../redux/actions';
const NotificationCenter = props => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [viewPaginationBtn, setViewPaginationBtn] = useState(false);
  const [unRead, setUnread] = useState(0);
  const notificationList = useSelector(({ app }) => (app.notificationCenter ? app.notificationCenter : []));
  const user = useSelector(({ user }) => user);
  const pageLimit = 10;
  const [pageNo, setPageNo] = useState(0);
  const dispatch = useDispatch();
  const closePanel = (uuid = '') => {
    if (uuid !== '') {
      let requestData = { isRead: true };
      notificationStatusUpdateAction(uuid, requestData)
        .then(res => {
          dispatch(notificationGetAction());
        })
        .catch(err => {
          console.log('error', err);
        });
    }
  };
  function isValidUrl(_string) {
    // eslint-disable-next-line
    const matchPattern = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;
    return matchPattern.test(_string);
  }
  const getNextPageData = () => {
    let tempList = JSON.parse(JSON.stringify(notificationList));
    let pageData = tempList.slice(0, pageNo * pageLimit + pageLimit);
    setData(pageData);
    if (pageNo * pageLimit + pageLimit >= tempList.length) {
      setViewPaginationBtn(false);
    } else {
      setViewPaginationBtn(true);
    }
  };
  useEffect(() => {
    getNextPageData();
    if (notificationList) {
      let flt = notificationList.filter(v => !v.isRead);
      setUnread(flt.length);
      if (props.setUnread) {
        props.setUnread(flt.length);
      }
    }
    // eslint-disable-next-line
  }, [notificationList, pageNo]);

  const pushDataLayer = (elementTag, refId, refType, elementType = 'link') => {
    let url = `${window.location.pathname}${window.location.search ? `&${window.location.search}` : ''}`;
    if (window.gtmDataLayer) {
      window.gtmDataLayer.push({
        event: 'element_clicked',
        elementType: elementType,
        path: url,
        userId: user?.id || '',
        email: user?.email || '',
        username: user?.username || '',
        name: user?.name || '',
        refId: refId,
        refType: refType,
        elementTag: elementTag,
        createdDate: new Date(),
      });
    }
  };
  if (!props.isMobile) {
    return (
      <>
        <span className="mat-icon mat-badge mat-badge-warn material-icons mat-badge-overlap mat-badge-above mat-badge-after mat-badge-medium" aria-hidden="true">
          add_alert{' '}
          <span id="mat-badge-content-0" className="mat-badge-content mat-badge-active">
            {unRead}
          </span>
        </span>
        <div className="notification-center">
          <div className="notify-header">
            <p> {unRead === 0 ? 'No Any New Notifications' : `${unRead} New Notifications`}</p>
          </div>
          <div className="notifications">
            {data.map((v, i) => {
              return (
                <div className="list-notification">
                  <div className="info">
                    <p>{v.shortMsg}</p>
                  </div>
                  <div className="icon">
                    {isValidUrl(v.url) ? (
                      <a
                        id={`notification-id-${v.uuid}`}
                        href={v.url}
                        onClick={e => {
                          closePanel(!v.isRead ? v.uuid : '');
                          pushDataLayer('notification-click', v.uuid, 'notification');
                        }}
                        target="new"
                        title={v.isRead ? 'Mark unread' : 'Mark as read'}>
                        <span className="mat-icon black material-icons" aria-hidden="true" data-mat-icon-type="font">
                          {v.isRead ? 'mark_chat_unread' : 'mark_chat_read'}{' '}
                        </span>
                      </a>
                    ) : (
                      <Link
                        id={`notification-id-${v.uuid}`}
                        to={v?.url || false}
                        onClick={e => {
                          closePanel(!v.isRead ? v.uuid : '');
                          pushDataLayer('notification-click', v.uuid, 'notification');
                        }}
                        title={v.isRead ? 'Mark unread' : 'Mark as read'}>
                        <span className="mat-icon black material-icons" aria-hidden="true" data-mat-icon-type="font">
                          {v.isRead ? 'mark_chat_unread' : 'mark_chat_read'}{' '}
                        </span>
                      </Link>
                    )}
                  </div>
                </div>
              );
            })}
            <div className="load-more">
              {notificationList.length > 0 ? (
                viewPaginationBtn ? (
                  <Button
                    className="viewMore"
                    onClick={() => {
                      setPageNo(pageNo + 1);
                      pushDataLayer('notification_view_more_btn', '', 'notification_view_more_btn', 'Button');
                    }}
                    id="notification_view_more_btn">
                    {t('View More')}
                  </Button>
                ) : (
                  <button mat-raised-button="" className="mat-focus-indicator btn menu-btn mat-raised-button mat-button-base">
                    <span className="mat-button-wrapper">You're all caught up </span>
                    <span className="mat-ripple mat-button-ripple"></span>
                    <span className="mat-button-focus-overlay"></span>
                  </button>
                )
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="notify-header">
          <p> {unRead} New Notifications</p>
          <span
            onClick={() => {
              props.mobileMenuClickHandler('notification');
            }}
            className="mat-icon material-icons"
            aria-hidden="true">
            close{' '}
          </span>
        </div>
        <div className="notifications">
          {data.map((v, i) => {
            return (
              <div className="list-notification">
                <div className="notification">
                  <div className="icon">
                    {isValidUrl(v.url) ? (
                      <a
                        id={`notification-id-${v.uuid}`}
                        href={v.url}
                        onClick={e => {
                          closePanel(!v.isRead ? v.uuid : '');
                          pushDataLayer('notification-click', v.uuid, 'notification');
                        }}
                        target="new"
                        title={v.isRead ? 'Mark unread' : 'Mark as read'}>
                        <span className="mat-icon black material-icons">{v.isRead ? 'mark_chat_unread' : 'mark_chat_read'} </span>
                      </a>
                    ) : (
                      <Link
                        id={`notification-id-${v.uuid}`}
                        to={v.url !== '' ? v.url : false}
                        onClick={e => {
                          closePanel(!v.isRead ? v.uuid : '');
                          pushDataLayer('notification-click', v.uuid, 'notification');
                        }}
                        title={v.isRead ? 'Mark unread' : 'Mark as read'}>
                        <span className="mat-icon black material-icons">{v.isRead ? 'mark_chat_unread' : 'mark_chat_read'} </span>
                      </Link>
                    )}
                  </div>
                  <div className="info">
                    <p className="black">{v.shortMsg}</p>
                  </div>
                </div>
                <div className="notification-cta icon">
                  <span className="mat-icon material-icons">chevron_right </span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="load-more">
          {notificationList.length > 0 ? (
            viewPaginationBtn ? (
              <Button
                className="viewMore"
                onClick={() => {
                  setPageNo(pageNo + 1);
                  pushDataLayer('notification_view_more_btn', '', 'notification_view_more_btn', 'Button');
                }}
                id="notification_view_more_btn">
                {t('View More')}
              </Button>
            ) : (
              <button mat-raised-button="" className="mat-focus-indicator btn menu-btn mat-raised-button mat-button-base">
                <span className="mat-button-wrapper">You're all caught up </span>
                <span className="mat-ripple mat-button-ripple"></span>
                <span className="mat-button-focus-overlay"></span>
              </button>
            )
          ) : (
            ''
          )}
        </div>
      </>
    );
  }
};
export default React.memo(NotificationCenter);
