import React from "react";
import { render } from "react-dom";
import App from "./App";
import {Provider} from "react-redux";
import {store, persistor} from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";
import "./i18n";
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
  } from '@material-ui/pickers';

render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<App />
			</MuiPickersUtilsProvider>
		</PersistGate>
	</Provider>
	, document.getElementById("root"));
