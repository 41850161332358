import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getCampaignSubsetData } from '../../redux/actions';
import IsLoadingHOC from '../../components/IsLoadingHOC';

const ProgramPerks = props => {
  const [data, setData] = useState([]);
  useEffect(() => {
    props.setLoading(true);
    getCampaignSubsetData({ reqAttributes: { pointTierConfig: 1 } })
      .then(response => {
        if (response.data.content) {
          setData(response.data.content[0].pointTierConfig);
        }
        props.setLoading(false);
      })
      .catch(ex => {
        console.log('Error In API Call', ex);
        setData([]);
        props.setLoading(false);
      });
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <div className="support-page">
        <div className="page-heading profile-img">
          <img src="/public/assets/images/contest.jpg" alt="Redeem Your Leap® Smart Rewards" />
          <div className="overlay-mask">
            <h1>Level Up To Earn New Perks</h1>
            <p>The more points you earn, the better the prize you can redeem</p>
          </div>
        </div>
      </div>
      <div className="page">
        <Container>
          <Row className="justify-content-center my-5">
            <Col sm="10">
              <div className="pb-5 text-center">
                <h1>
                  READY TO <strong>LEVEL UP</strong>?
                </h1>
                <p>The more Leap® Smart Rewards points you earn, the higher your level. Unlock bonus perks, new rewards, and exciting giveaway opportunities just for continuing to enjoy Leap products.</p>
              </div>
              {data.map((v, i) => {
                return (
                  <Row className="mt-5">
                    <Col sm={3} className="text-center">
                      <img src={`/public/assets/images/levels/tier_icon_${v.tierId}.png`} />
                    </Col>
                    <Col sm={9} className="my-auto">
                      <h2 className="upper-caps">{v.tierName}</h2>
                      <p>{v.desc}</p>
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};
export default IsLoadingHOC(ProgramPerks, 'Loading....');
