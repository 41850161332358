import React, { useState, useEffect } from 'react';
import { getUserDetails, updateUserDetails } from '../../redux/actions';
import { connect } from 'react-redux';
import IsLoadingHOC from '../../components/IsLoadingHOC';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GooglePlaces from '../../components/GooglePlaces';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, MenuItem } from '@material-ui/core';
import InputMask from 'react-input-mask';

const useStyles = makeStyles(theme => ({
  disabled: { color: '#000' },
}));
const genderlist = [
  {
    value: 'm',
    label: 'Male',
  },
  {
    value: 'f',
    label: 'Female',
  },
  {
    value: 'na',
    label: 'No Choice',
  },
];
export const camelToTitle = camelCase =>
  camelCase
    .replace(/([A-Z])/g, match => ` ${match}`)
    .replace(/^./, match => match.toUpperCase())
    .trim();
var validator = require('validator');
const Profile = ({ getUserDetails, updateUserDetails, setLoading }) => {
  const { t } = useTranslation();
  const confige = useSelector(state => state.app.config);
  const user = useSelector(state => state.user);
  const history = useHistory();
  const [place, setPlace] = useState({});
  const classes = useStyles();

  /*[START:INITIALIZE_DECLARATION]*/
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    birth: '',
    homePhone: '',
    cellPhone: '',
    address: '',
    address2: '',
    country: '',
    state: '',
    suite: '',
    city: '',
    zip: '',
    shippingInstructions: '',
    gender: '',
    receiveEmail: '',
    receiveSms: '',
    companyName: '',
    instagramHandle: '',
    facebookHandle: '',
    tiktokHandle: '',
    twitterHandle: '',
    socialSecurityNumber: '',
  });
  /*[END:INITIALIZE_DECLARATION]*/
  const [validation, setValidation] = useState({});
  const [error, setError] = useState({ firstName: '', email: '', homePhone: '', cellPhone: '', lastName: '', username: '', address: '', address2: '', country: '', state: '', suite: '', zip: '', city: '', shippingInstructions: '', receiveEmail: '', receiveSms: '', gender: '', instagramHandle: '', facebookHandle: '', tiktokHandle: '', twitterHandle: '', socialSecurityNumber: '' });
  //const [calender, setCalender] = useState(false);
  const [bonusPoints, setBonusPoints] = useState(0);
  useEffect(() => {
    if (confige.bonusPointInfo) {
      if (confige.bonusPointInfo.onProfileComplete) {
        setBonusPoints(confige.bonusPointInfo.onProfileComplete.points ? confige.bonusPointInfo.onProfileComplete.points : 0);
      }
    }
  }, [confige, confige.bonusPointInfo]);
  useEffect(() => {
    setLoading(true);
    getUserDetails(user.id)
      .then(response => {
        setLoading(false);
        setData({ ...data, ...response.data });
      })
      .catch(error => {
        setLoading(false);
        console.log('error', error);
      });
    let items = {};
    if (confige.uiConfig) {
      confige.uiConfig.userFields.forEach(element => {
        if (['firstName', 'lastName', 'gender', 'cellPhone'].indexOf(element.name) >= 0) {
          element.validation.mandatory = true;
        }
        items[element.name] = element.validation;
      });
    }
    setValidation(items);
    // eslint-disable-next-line
  }, []);

  const handleChange = e => {
    let { name, value } = e.target;
    let errorMessage = '';
    let validationArray = validation[name];
    let check = false;
    console.log(name, value);
    if (name === 'cellPhone') {
      value = value.substring(1);
    }
    for (let key in validationArray) {
      if (key === 'minLength' && value.length !== 0) {
        if (value.length < validationArray[key]) {
          if (name === 'cellPhone') {
            errorMessage = `${t('Please enter a valid US phone number')}.`;
          } else {
            errorMessage = `${t('Should not be less then', { count: validationArray[key] })}.`;
          }
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'maxLength') {
        if (value.length > validationArray[key]) {
          if (name === 'cellPhone') {
            errorMessage = `${t('Please enter a valid US phone number')}.`;
          } else {
            errorMessage = `${t('Should not be greater then', { count: validationArray[key] })}.`;
          }
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'mandatory' && validationArray[key]) {
        if (!value.length) {
          errorMessage = `${name === 'cellPhone' ? 'Phone Number' : camelToTitle(name)} ${t('is required')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'email' && validationArray[key]) {
        if (!validator.isEmail(value)) {
          errorMessage = `${t('Please enter a valid email')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'upperCaseReq' && validationArray[key]) {
        if (value.search(/[A-Z]/) < 0 && value.length !== 0) {
          errorMessage = `${t('Should have atleast one Upper Case latter')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'lowerCaseReq' && validationArray[key] && value.length !== 0) {
        if (value.search(/[a-z]/) < 0) {
          errorMessage = `${t('Should have atleast one Lower Case latter')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
      if (key === 'specialCharReq' && validationArray[key]) {
        // eslint-disable-next-line
        var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        if (!format.test(value) && value.length !== 0) {
          errorMessage = `${t('Should have atleast one special letter')}.`;
          setError({ ...error, [name]: errorMessage });
          check = true;
        }
      }
    }

    let nameFormat = /[&\/\\#,+()$~`%'":.*?<>{}\[\]!@^;=_|0-9\s]/g;
    if (name === 'firstName' && nameFormat.test(value) && value.length !== 0) {
      setError({ ...error, [name]: 'First name must not contain any numeric digit and special character except the hyphen (-)' });
      check = true;
    }
    if (name === 'lastName' && nameFormat.test(value) && value.length !== 0) {
      setError({ ...error, [name]: 'Last name must not contain any numeric digit and special character except the hyphen (-)' });
      check = true;
    }
    if (!check) {
      setError({ ...error, [name]: '' });
    }
    setData({ ...data, [name]: value });
  };
  const onSubmit = e => {
    e.preventDefault();
    let errorArray = {};
    for (let name in data) {
      let value = data[name];
      let validationArray = validation[name];

      let errorMessage = '';

      let nameFormat = /[&\/\\#,+()$~`%'":.*?<>{}\[\]!@^;=_|0-9\s]/g;
      if (name === 'firstName' && nameFormat.test(value) && value.length !== 0) {
        errorMessage = 'First name must not contain any numeric digit and special character except the hyphen (-)';
      }
      if (name === 'lastName' && nameFormat.test(value) && value.length !== 0) {
        errorMessage = 'Last name must not contain any numeric digit and special character except the hyphen (-)';
      }

      if (name === 'birth' && isNaN(new Date(value)) && value.length !== 0) {
        errorMessage = `${t('Invalid')} ${t('Birth Date')}.`;
      } else if (name === 'birth') {
        errorMessage = '';
      }
      if (name === 'cellPhone') {
        value = value.substring(1);
      }

      if (value !== null) {
        for (let key in validationArray) {
          if (key === 'email' && validationArray[key] && !validator.isEmail(value)) {
            errorMessage = `${t('Please enter a valid email')}.`;
          } else if (key === 'minLength' && value.length !== 0) {
            if (value.length < validationArray[key]) {
              if (name === 'cellPhone') {
                errorMessage = `${t('Please enter a valid US phone number')}.`;
              } else {
                errorMessage = `${t('Should not be less then', { count: validationArray[key] })}.`;
              }
            }
          } else if (key === 'maxLength' && value.length > validationArray[key]) {
            if (name === 'cellPhone') {
              errorMessage = `${t('Please enter a valid US phone number')}.`;
            } else {
              errorMessage = `${t('Should not be greater then', { count: validationArray[key] })}.`;
            }
          } else if (key === 'hasNumeric' && (name === 'firstName' || name === 'lastName') && !validationArray[key] && value.search(/[0-9]/) >= 0) {
            errorMessage = t('Should not be contain number');
          } else if (key === 'hasNumeric' && (name === 'firstName' || name === 'lastName') && validationArray[key] && value.search(/[0-9]/) <= 0) {
            errorMessage = t('Should be contain number');
          } else if (key === 'mandatory' && validationArray[key] && !value.length) {
            errorMessage = `${name === 'cellPhone' ? 'Phone Number' : camelToTitle(name)} ${t('is required')}.`;
          } else if (key === 'upperCaseReq' && value.search(/[A-Z]/) < 0 && value.length !== 0) {
            errorMessage = `${t('Should have atleast one Upper Case latter')}.`;
          } else if (key === 'lowerCaseReq' && value.search(/[a-z]/) < 0 && value.length !== 0) {
            errorMessage = `${t('Should have atleast one Lower Case latter')}.`;
          } else if (key === 'hasNumeric' && validationArray[key] && value.search(/[0-9]/) < 0) {
            errorMessage = t('Should be contain number');
          } else if (key === 'hasAlpha' && validationArray[key] && value.search(/[A-Z]/) < 0 && value.search(/[a-z]/) < 0) {
            errorMessage = t('Should be contain alphabets');
          } else if (key === 'specialCharReq' && value.length !== 0) {
            // eslint-disable-next-line
            var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            if (!format.test(value)) {
              errorMessage = `${t('Should have atleast one special letter')}.`;
            }
          }
        }
        errorArray[name] = errorMessage;
      }
    }
    setError(errorArray);
    let check = false;
    for (let key in errorArray) {
      if (errorArray[key]) {
        check = true;
      }
    }
    if (!check) {
      if (data.birth) {
        data.birth = formatDate(data.birth);
      }

      setLoading(true);
      updateUserDetails(user.id, data)
        .then(response => {
          if (response.httpCode === 201) {
            toast.success(`Thanks for coming back! You’ve earned ${bonusPoints} points.`);
          } else {
            toast.success(`Successfully updated your profile.`);
          }
          history.push('/');
          setLoading(false);
        })
        .catch(error => {
          // console.log(error);
          let errorMessage = 'Something went wrong!';
          if (Array.isArray(error)) {
            error.forEach(item => {
              if (item.type && item.message) {
                errorMessage = errorMessage + ` ${camelToTitle(item.type)} - ${Array.isArray(item.message) ? item.message.join(',') : item.message}`;
              }
            });
          }
          toast.error(errorMessage);
          setLoading(false);
        });
    }
  };

  const formatDate = value => {
    value = new Date(value);
    const dd = String(value.getDate()).padStart(2, '0');
    const mm = String(value.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = value.getFullYear();
    const formatedDate = yyyy + '-' + mm + '-' + dd;
    return formatedDate;
  };

  const handleDate = value => {
    //const formatedDate = formatDate(value);
    //setCalender(false);
    setData({ ...data, birth: value });
  };
  useEffect(() => {
    if (place.hasOwnProperty('address')) {
      let curStateData = Object.assign({}, data);
      if (curStateData.hasOwnProperty('address')) {
        curStateData.address = place.address;
      }
      // if (curStateData.hasOwnProperty("country")) {
      // 	curStateData.country = place.country;
      // }
      if (curStateData.hasOwnProperty('state')) {
        curStateData.state = place.state;
      }
      if (curStateData.hasOwnProperty('city')) {
        curStateData.city = place.city;
      }
      // if (curStateData.hasOwnProperty("suite")) {
      // 	curStateData.suite = place.street;
      // }
      if (curStateData.hasOwnProperty('zip')) {
        curStateData.zip = place.zip;
      }
      setData(curStateData);
    }
    // eslint-disable-next-line
  }, [place]);
  const googleSelectedAddressHandler = place => {
    setPlace(place);
  };
  const formatChars = {
    '1': '[12]',
    '0': '[0123456789]',
  };
  return (
    <div>
      <div className="page-heading profile-img">
        <img src="/public/assets/images/profile-manage.jpg" alt="Your Profile Account" />
        <div className="overlay-mask">
          <h1>Manage Your Smart Rewards Account</h1>
        </div>
      </div>
      <div className="page">
        <div className="container-fluid">
          <form role="form" method="post">
            <div className="col">
              <h2 className="text-left">
                <strong>Account Information</strong>
              </h2>
              <p className="mt-4">
                <span className="bold">Email:</span> <span className="theme-color">{user.email}</span>
              </p>
            </div>
            <div className="row mx-1">
              {/*[START:FIRSTNAME]*/}
              <div className="form-group outline-field col-md-6 mt-3 theme-profile-firstName">
                <TextField variant="outlined" name="firstName" type="text" id="first-name" label={`${t('lang_key_fname')}`} value={data.firstName !== null ? data.firstName : ''} onChange={handleChange} helperText={error.firstName} error={error.firstName} aria-required="true" autoComplete="name" required={validation['firstName'] && validation['firstName'].mandatory} />
              </div>
              {/*[END:FIRSTNAME]*/}

              {/*[START:LASTNAME]*/}
              <div className="form-group outline-field col-md-6 mt-3 theme-profile-lastName">
                <TextField variant="outlined" name="lastName" type="text" id="lastName" label={`${t('lang_key_lname')}`} value={data.lastName !== null ? data.lastName : ''} onChange={handleChange} helperText={error.lastName} error={error.lastName} aria-required="true" autoComplete="family-name" required={validation['lastName'] && validation['lastName'].mandatory} />
              </div>
              {/*[END:LASTNAME]*/}

              {/*[START:ADDRESS]*/}
              <div className="form-group outline-field col-md-12 mt-3 theme-signup-address-finder">
                <GooglePlaces required={validation['address'] && validation['address'].mandatory} errorMessage={error.address} getPlaceHandler={googleSelectedAddressHandler} placeholder={t('lang_key_select_address')} value={data.address} handleChange={handleChange} />
              </div>
            </div>
            <div className="row mx-1">
              <div className="form-group outline-field col-md-4 mt-3 theme-profile-address">
                <TextField
                  variant="outlined"
                  name="address"
                  type="text"
                  id="address"
                  label={`${t('Selected Address')}`}
                  value={data.address ? data.address : ''}
                  onChange={handleChange}
                  helperText={error.address}
                  error={error.address}
                  aria-required="true"
                  autoComplete="address"
                  required={validation['address'] && validation['address'].mandatory}
                  disabled
                  InputProps={{
                    classes: {
                      disabled: classes.disabled,
                    },
                  }}
                />
              </div>
              {/*[END:ADDRESS]*/}
              {/*[START:ADDRESS2]*/}
              {/* <div className="form-group outline-field col-md-3 theme-signup-address">
								<label htmlFor="address2" className="field-label ">
									<Trans>lang_key_add_address2</Trans>
									{validation["address2"] && validation["address2"].mandatory ? <Required /> : ""}
								</label>
								<div className="col-sm-12">
									<input type="text" 
									value={data.address2} 
									name="address2" 
									className="form-control" 
									id="address2" 
									placeholder={`${t("Enter ")}${t("lang_key_add_address2")}`}
									/>
									<span className="input-error" role="alert">{error.address2}</span>
								</div>
							</div> */}
              {/*[END:ADDRESS2]*/}

              {/*[START:CITY]*/}
              <div className="form-group outline-field col-md-3 mt-3 theme-profile-city">
                <TextField
                  variant="outlined"
                  name="city"
                  type="text"
                  id="city"
                  label={`${t('City')}`}
                  value={data.city ? data.city : ''}
                  onChange={handleChange}
                  helperText={error.city}
                  error={error.city}
                  aria-required="true"
                  autoComplete="city"
                  required={validation['city'] && validation['city'].mandatory}
                  disabled
                  InputProps={{
                    classes: {
                      disabled: classes.disabled,
                    },
                  }}
                />
              </div>
              {/*[END:CITY]*/}
              {/*[START:STATE]*/}
              <div className="form-group outline-field col-md-3 mt-3 theme-profile-state">
                <TextField
                  variant="outlined"
                  name="state"
                  type="text"
                  id="state"
                  label={`${t('lang_key_add_state')}`}
                  value={data.state ? data.state : ''}
                  onChange={handleChange}
                  helperText={error.state}
                  error={error.state}
                  aria-required="true"
                  autoComplete="state"
                  required={validation['state'] && validation['state'].mandatory}
                  disabled
                  InputProps={{
                    classes: {
                      disabled: classes.disabled,
                    },
                  }}
                />
              </div>
              {/*[END:STATE]*/}

              {/*[START:ZIP]*/}
              <div className="form-group outline-field col-md-2 mt-3 theme-profile-zip">
                <TextField
                  variant="outlined"
                  name="zip"
                  type="text"
                  id="zip"
                  label={`${t('Zip Code')}`}
                  value={data.zip ? data.zip : ''}
                  onChange={handleChange}
                  helperText={error.zip}
                  error={error.zip}
                  aria-required="true"
                  autoComplete="zip"
                  required={validation['zip'] && validation['zip'].mandatory}
                  disabled
                  InputProps={{
                    classes: {
                      disabled: classes.disabled,
                    },
                  }}
                />
              </div>
              {/*[END:ZIP]*/}

              {/*[START:GENDER]*/}
              <div className="form-group outline-field col-md-6 mt-3 theme-profile-gender">
                <TextField variant="outlined" select label={t('Gender')} name="gender" onChange={handleChange} value={data.gender !== null ? data.gender : ''} id="gender" helperText={error.gender} error={error.gender} required={validation['gender'] && validation['gender'].mandatory}>
                  {genderlist.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              {/*[END:GENDER]*/}

              {/*[START:CELLPHONE]*/}
              <div className="form-group outline-field col-md-6 mt-3 theme-profile-homePhone">
                <InputMask mask="+1999999999999999" value={data.cellPhone} onChange={handleChange} maskChar={null} alwaysShowMask={false} error={error.cellPhone}>
                  {() => <TextField variant="outlined" name="cellPhone" type="text" id="cellPhone" label={`${t('Phone Number')}`} helperText={error.cellPhone} aria-required="true" autoComplete="cellPhone" required={validation['cellPhone'] && validation['cellPhone'].mandatory} />}
                </InputMask>
              </div>
              {/*[END:CELLPHONE]*/}

              <div className="col-md-12 text-center mt-5">
                <button type="submit" role="submit" id="profile-submit-btn" onClick={onSubmit} className="btn btn-primary">
                  Save Changes
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { getUserDetails, updateUserDetails })(IsLoadingHOC(Profile, 'Wait .....'));
