import React from "react";
import PortalPointList from "../../components/PortalPointList";
import CarouselCustom from "../../components/CarouselCustom";
const EarnPoints = (props) => {
	return (
		<>
			<div className="">
				<div className="page-heading profile-img">
					<img src="/public/assets/images/earn-points.jpg" alt="Redeem Your Leap® Smart Rewards" />
					<div className="overlay-mask">
						<h1>Earn Points Towards Exciting Rewards</h1>
						<p>The more points you earn, the better the prize you can redeem</p>
					</div>
				</div>
			</div>
			<PortalPointList pagesize={100} viewPageBtn={true}/>
		</>
	);
};

export default EarnPoints;
