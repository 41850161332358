import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { portalPointAction, helpQueryAction } from '../../redux/actions';
import { Container, Row } from 'react-bootstrap';
import IsLoadingHOC from '../IsLoadingHOC';
import { Trans, useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Captcha from '../CaptchaClick';
const EarnPointList = props => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    name: '',
    email: '',
    description: '',
    captchaVal: '',
  });
  const config = useSelector(state => state.app.config);
  const user = useSelector(state => state.user);
  let [activityCurIndex, setActivityCurIndex] = useState(0);
  let [captchaValue, setCaptchaValue] = useState(false);
  let [allActivityList, setAllActivityList] = useState([]);

  let [resetCaptchaValue, setResetCaptchaValue] = useState(0);
  const [error, setError] = useState({ name: '', email: '', description: '', captchaVal: '' });
  const validationError = { description: '', name: /^[A-Za-z]+$/, email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, lastName: /^[A-Za-z]+$/, captchaVal: '' };
  const [description, setDescription] = useState('');
  useEffect(() => {
    getNextData(0);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setData({ ...data, name: user.name ? user.name : 'Member', email: user.email });
    // eslint-disable-next-line
  }, [user]);
  const getNextData = page => {
    const actionType = ['view', 'survey', 'refer', 'read', 'share'];
    props.setLoading(true);
    props
      .portalPointAction(page + 1, actionType, 100)
      .then(response => {
        let activityList = response.data;
        if (activityList) {
          activityList = activityList.map(v => {
            let o = Object.assign({}, v);
            if (o.isTierEnabled && o.tierPoints) {
              o.points = o.tierPoints;
            }
            return o;
          });
          let ind = activityList.findIndex(v => v.id === props.id);
          if (ind > 0) {
            setActivityCurIndex(ind);
          }
        }
        setAllActivityList([...allActivityList, ...activityList]);
        props.setLoading(false);
      })
      .catch(ex => {
        console.log('Error In API Call', ex);
        props.setLoading(false);
      });
  };
  const onSubmit = e => {
    e.preventDefault();
    let reqd = JSON.parse(JSON.stringify(data));
    let errd = JSON.parse(JSON.stringify(error));
    let isError = false;
    for (let key in reqd) {
      let val = reqd[key].trim();
      if (key === 'name') {
        if (!val || val.length < 1) {
          isError = true;
          errd[key] = `Name is required.`;
        } else {
          errd[key] = ``;
        }
      } else if (key === 'email') {
        if (val === '') {
          errd[key] = `Email is required.`;
          isError = true;
        } else if (!val.match(validationError[key])) {
          errd[key] = `Please enter a valid email address.`;
          isError = true;
        } else {
          errd[key] = ``;
        }
      } else if (key === 'description') {
        if (!val || val.length < 3) {
          errd[key] = `Please enter a valid at least 3 characters message.`;
          isError = true;
        } else {
          errd[key] = ``;
        }
      } else if (key === 'captchaVal' && captchaEnable) {
        if (!val || val.length < 2) {
          errd[key] = `Please check Captcha`;
          isError = true;
        } else {
          errd[key] = ``;
        }
      }
    }
    setError(errd);
    if (!isError) {
      props.setLoading(true);
      let requestData = { email: reqd.email, name: reqd.name, message: reqd.description };
      if (captchaEnable) {
        requestData['captchaVal'] = reqd.captchaVal;
      }
      helpQueryAction(requestData)
        .then(response => {
          reqd['description'] = '';
          setResetCaptchaValue(resetCaptchaValue + 1);
          setData(reqd);
          toast.success(`Thank you for contacting us. Your message has been successfully sent. We will contact you very soon!`);
          props.setLoading(false);
        })
        .catch(error => {
          toast.error(t('Something went wrong'));
          props.setLoading(false);
          setResetCaptchaValue(resetCaptchaValue + 1);
        });
    }
  };
  let [captchaEnable, setCaptchaEnable] = useState(false);
  useEffect(() => {
    let errorArray = JSON.parse(JSON.stringify(error));
    if (captchaValue) {
      errorArray['captchaVal'] = '';
      let reqd = JSON.parse(JSON.stringify(data));
      reqd.captchaVal = captchaValue;
      setData(reqd);
    }
    setError(errorArray);
    // eslint-disable-next-line
  }, [captchaValue]);
  useEffect(() => {
    if (config.captchaConfig) {
      if (config.captchaConfig.supportCaptcha) {
        setCaptchaEnable(true);
      }
    }
  }, [config]);
  const handleChange = e => {
    let key = e.target.name;
    let val = e.target.value;
    let reqd = JSON.parse(JSON.stringify(data));
    let errd = JSON.parse(JSON.stringify(error));
    if (val.match(validationError[key]) || val === '' || key === 'email') {
      reqd[key] = val;
    }
    if (key === 'name') {
      if (!reqd[key] || reqd[key].trim().length < 1) {
        errd[key] = `Name is required.`;
      } else {
        errd[key] = ``;
      }
    } else if (key === 'email') {
      if (val === '') {
        errd[key] = `Email is required.`;
      } else if (!reqd[key].match(validationError[key])) {
        errd[key] = `Please enter a valid email address.`;
      } else {
        errd[key] = ``;
      }
    } else if (key === 'description') {
      if (!reqd[key] || val.trim().length < 3) {
        errd[key] = `Please enter a valid at least 3 characters message.`;
      } else {
        errd[key] = ``;
      }
    }
    setError(errd);
    setData(reqd);
  };
  return (
    <Container fluid>
      <div className="link-counter">
        <Row>
          <div className="col-4 center-item">
            <a title="PREVIOUS" href={activityCurIndex > 0 && allActivityList.length > 0 ? `/earn-points-action-preview/${allActivityList[activityCurIndex - 1].id}` : '/earn-points'} className="btn btn-primary btn-prev" id="previous-btn">
              {'<'} PREVIOUS
            </a>
          </div>
          <div className="col-4 center-item text-center"></div>
          <div className="col-4 center-item text-right">
            <a title="Next" className="btn btn-primary btn-next" id="next-btn" href={activityCurIndex < allActivityList.length - 1 ? `/earn-points-action-preview/${allActivityList[activityCurIndex + 1].id}` : '/earn-points'}>
              NEXT {'>'}
            </a>
          </div>
        </Row>
      </div>
      <Row className="justify-content-center watch-video pb-5">
        <div className="col-sm-7 support-form-wrapper">
          <form>
            <div className="support-form">
              <div className="text-center">
                <p className="text-center">What did you think? Send us a message and tell us what you want to see more of!</p>
              </div>
              <div className="text-center mb-4 mx-3">
                <TextField variant="filled" multiline={true} minRows={8} id="description" name="description" label="Message" helperText={error.description} error={error.description} value={data.description} onChange={handleChange} aria-required="true" />
              </div>
              {captchaEnable ? (
                <div className="text-center mb-4 mx-3">
                  <Captcha reset={resetCaptchaValue} parentCallback={setCaptchaValue} />
                  <div className="input-error" role="alert">
                    {error.captchaVal}
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="text-center my-2">
                <button type="submit" role="submit" onClick={onSubmit} className="btn btn-primary btn-submit" id="support-submit-btn">
                  <Trans>Submit</Trans>
                </button>
              </div>
            </div>
          </form>
        </div>
      </Row>
    </Container>
  );
};

export default connect(null, { portalPointAction })(IsLoadingHOC(EarnPointList, 'Loading....'));
