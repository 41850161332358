import React, { useState } from "react";
import { Row, Col, Accordion, Card, Button, Nav } from "react-bootstrap";
const FrequentlyAskedQuestions = () => {
	const [data, setData] = useState([
		{ id: 0, clicked: false, name: "What is Leap® Rewards?", content: "Leap Smart Rewards is a loyalty program that allows Leap customers to earn points towards exciting prizes and entries into giveaway and sweepstakes." },
		{ id: 1, clicked: false, name: "How do I earn Leap® Rewards points?", content: "You can earn points for all sorts of activities, including uploading codes found in Leap products, taking surveys, referring friends, and more." },
		{ id: 2, clicked: false, name: "What rewards can I earn with Leap® Rewards points?", content: "Leap Smart Rewards points can be redeemed for digital gift cards, with new rewards coming soon." },
	]);
	const [faqStatus, setFaqStatus] = useState(false);
	const toggleHandler = (i) => {
		let tempData = JSON.parse(JSON.stringify(data));
		tempData[i].clicked = tempData[i].clicked ? false : true;
		setData(tempData);
	};
	const bulkActionHandler = () => {
		let status = !faqStatus;
		setFaqStatus(status);
		let tempData = data.map((v) => ({ ...v, clicked: status }));
		setData(tempData);
	};
	return (
		<div className="container">
			<div className="page">
				<Row className="justify-content-md-center section-content faq" id="faq-content">
					<Col md={12}>
						<h1 className="text-center pb-3">Frequently Asked Questions</h1>
					</Col>
					<Col md={11} className="text-right my-3">
						<Button onClick={bulkActionHandler} variant="link">
							{!faqStatus ? "Open All" : "Close All"}
						</Button>
					</Col>
					<Col md={11}>
						<Accordion>
							{data.map((v, i) => {
								return (
									<Card style={{ marginBottom: "0px" }}>
										<Card.Header>
											<Accordion.Toggle activeKey={v.clicked} as={Nav} variant="link" onClick={(e) => toggleHandler(i)} eventKey={v.id}  data-toggle="collapse" aria-expanded="true" aria-controls="collapse">
												<Row style={{ cursor: "pointer" }}>
													<Col md={11}>{v.name}</Col>
													<Col md={1} className="text-right pl-0 faq_open-close-icon">
														{v.clicked ? "−" : "+"}
													</Col>
												</Row>
											</Accordion.Toggle>
										</Card.Header>
										{v.clicked ? (
											<Accordion>
												<Card.Body>{v.content}</Card.Body>
											</Accordion>
										) : (
											<Accordion.Collapse>
												<Card.Body>{v.content}</Card.Body>
											</Accordion.Collapse>
										)}
									</Card>
								);
							})}
						</Accordion>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default FrequentlyAskedQuestions;
