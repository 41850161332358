import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const ExcitingChanges = () => {
	return (
        <div>
			<div className="submit-page">
				<Container>
					<Row>
						<Col sm="12" className="my-5">
							<h1 className="text-left">Exciting Changes are Coming to Leap® Smart Rewards</h1>
							<p className="mt-5">At Leap, we're constantly looking to improve your loyalty experience. Many of you only take advantage of the Smart Rewards program through redeeming purchases for points. We want you to know that you are missing additional chances to earn points through our sweepstakes, promotions, and tier-based perks.</p>
							<p className="mt-4">While the $10 Gift Card (Yellow Sticker) promotion has recently ended, you'll soon discover new ways to earn points without requiring a purchase. With these new additions, we will adjust the number of points you receive for every pin code uploaded. Please keep an eye on the Submit Code page for more info. In the meantime, continue enjoying Leap products and rack up points for even more fabulous prizes and rewards coming soon!</p>
							<p className="mt-4">The Leap Team</p>
						</Col>
                    </Row>
                </Container>
            </div>
        </div>
	);
};

export default ExcitingChanges;